import { useEffect } from 'react';
import type { TaskListProps } from './types';
import TaskListPanel from './TaskListPanel';
import { buildTasksListUI } from './TasksList/utils';
import { TaskListStatus, useTasksByStatusesQuery } from 'services/graphql';
import { FETCH_POLICY } from 'App.constants';
import { useTasksDispatch } from 'context/tasks/TasksContext';
import { useTracking } from 'hooks/useTracking';
import { PageName } from 'config/pageNames';

export const TaskList = ({
  wardPatientId,
  showTaskListPanel,
  setShowTaskListPanel,
}: TaskListProps) => {
  const { data, error, loading, refetch } = useTasksByStatusesQuery({
    variables: {
      wardPatientId,
      statuses: [
        TaskListStatus.Draft,
        TaskListStatus.Pending,
        TaskListStatus.Complete,
      ],
      limit: null,
    },
    fetchPolicy: FETCH_POLICY.CACHE_AND_NETWORK,
  });
  const tasksDispatch = useTasksDispatch();
  const { trackEvent } = useTracking();

  const onModalClose = () => {
    setShowTaskListPanel(false);
    tasksDispatch({ type: 'resetTaskDetails' });
    tasksDispatch({ type: 'resetIsEditTask' });
  };

  useEffect(() => {
    if (data?.tasksByStatuses) {
      tasksDispatch({
        type: 'setTasks',
        tasks: buildTasksListUI(data?.tasksByStatuses ?? []),
      });
    }
  }, [data?.tasksByStatuses]);

  useEffect(() => {
    if (showTaskListPanel) {
      trackEvent('Opened Task List Panel', {
        pageName: PageName.PATIENT_DETAIL,
      });
    }
  }, [showTaskListPanel]);

  return (
    <TaskListPanel
      open={showTaskListPanel}
      setOpen={setShowTaskListPanel}
      onRequestClose={onModalClose}
      data={data}
      error={error}
      loading={loading}
      refetch={refetch}
    />
  );
};
