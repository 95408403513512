import {
  Wrapper,
  Text,
  Divider,
  colors,
  fontSizes,
  Button,
  Spacer,
} from '@bt-healthcare/ui-toolkit';

import { ConsultationStatusFilters } from '../Filters/ConsultationStatusFilters';
import { TestStatusFilters } from '../Filters/TestStatusFilters';
import { MedicalCentreFilters } from '../Filters/MedicalCentreFilters';
import { TaskDueFilters } from '../Filters/TaskDueFilters';
import { AccordionWrapper, TextWrapper } from './styles';
import type { DropdownMenuProps } from './types';
import { AccordionContent } from './AccordionContent';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useTracking } from 'hooks/useTracking';

export const DropdownMenu = ({
  statusFilter,
  handleFilterStatusSelect,
  clearFilter,
  handleTargetChecked,
}: DropdownMenuProps) => {
  const { isTaskListFeatureFlagEnabled } = useFeatureFlags();
  const { trackEvent } = useTracking();

  const totalFilters = statusFilter.length;
  const noOfConsultationStatusFilters =
    statusFilter.filter((item) => item.type === 'ConsultationStatus')?.length ??
    0;

  const noOfTestStatusFilters =
    statusFilter.filter((item) => item.type === 'TestStatus')?.length ?? 0;

  const noOfMedicalCentreFilters =
    statusFilter.filter((item) => item.type === 'MedicalCentre')?.length ?? 0;

  const noOfTaskDueFilters =
    statusFilter.filter((item) => item.type === 'TaskDueType')?.length ?? 0;

  return (
    <Wrapper className="dropdown" data-testid="dropdown-menu">
      <TextWrapper>
        <Text color={colors.grey.grey10} fontSize={fontSizes.h4}>
          Filter by
        </Text>
        {totalFilters > 0 && (
          <Wrapper
            onClick={clearFilter}
            className="clear-all"
            data-testid="status-filter-clear-all"
          >
            <Text
              color={colors.primaryIndigo.indigo08}
              fontSize={fontSizes.base}
              fontWeight={500}
            >
              Clear all
            </Text>
          </Wrapper>
        )}
      </TextWrapper>
      <Divider />
      <AccordionWrapper>
        <AccordionContent
          id="consultation-status"
          title="Consultation status"
          noOfFilters={noOfConsultationStatusFilters}
        >
          <ConsultationStatusFilters
            statusFilter={statusFilter}
            handleTargetChecked={handleTargetChecked}
          />
        </AccordionContent>
        <Spacer size="s4" />
        <AccordionContent
          id="test-status"
          title="Test status"
          noOfFilters={noOfTestStatusFilters}
        >
          <TestStatusFilters
            statusFilter={statusFilter}
            handleTargetChecked={handleTargetChecked}
          />
        </AccordionContent>
        <Spacer size="s4" />
        <AccordionContent
          id="medical-centre"
          title="Medical Centre"
          noOfFilters={noOfMedicalCentreFilters}
        >
          <MedicalCentreFilters
            statusFilter={statusFilter}
            handleTargetChecked={handleTargetChecked}
          />
        </AccordionContent>
        {isTaskListFeatureFlagEnabled && (
          <>
            <Spacer size="s4" />
            <AccordionContent
              id="task-due-filter"
              title="Task due"
              noOfFilters={noOfTaskDueFilters}
            >
              <TaskDueFilters
                statusFilter={statusFilter}
                handleTargetChecked={handleTargetChecked}
              />
            </AccordionContent>
          </>
        )}
      </AccordionWrapper>
      <Button
        id="apply-filter-button"
        onClick={() => {
          if (statusFilter.filter((x) => x.type === 'TaskDueType').length > 0) {
            trackEvent('Patient List - Filter next task due by types', {
              taskType: statusFilter
                .filter((x) => x.type === 'TaskDueType')
                .map((x) => x.value)
                .join(', '),
            });
          }

          handleFilterStatusSelect(statusFilter);
        }}
      >
        Apply
        {totalFilters > 0 && <> ({totalFilters})</>}
      </Button>
    </Wrapper>
  );
};
