import { Stack, toHyphenCase } from '@bt-healthcare/ui-toolkit';
import { useEffect } from 'react';
import { ColumnSortToggle } from './styles';
import type { ColumnSortProps } from './types';
import { WardPatientsSortKey } from 'services/graphql';
import { useTracking } from 'hooks/useTracking';

export const ColumnSort = <T extends WardPatientsSortKey>({
  setSortingQuery,
  sortParams,
  sortKey,
  label,
}: ColumnSortProps<T>) => {
  const identifier = `${toHyphenCase(sortKey).replace(/_/g, '-')}-column-sort`;
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (sortKey === WardPatientsSortKey.NextTaskDueTime) {
      trackEvent('Patient List - Sort tasks by next task due date', {
        ascending: sortParams?.ascending ? 'true' : 'false',
      });
    }
  }, [sortParams]);

  return (
    <Stack space="none" id={identifier} flexDirection="row">
      <ColumnSortToggle
        role="button"
        data-testid={identifier}
        onClick={() => {
          setSortingQuery({
            key: sortKey,
            ascending:
              sortParams?.key === sortKey ? !sortParams.ascending : true,
          });
        }}
      >
        {label}
      </ColumnSortToggle>
    </Stack>
  );
};
