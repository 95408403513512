import type { TypedOptionType } from '@bt-healthcare/ui-toolkit';
import {
  Button,
  DropDown,
  Spacer,
  colors,
  DatePicker,
  Input,
  Text,
  fontSizes,
  useClickOutside,
} from '@bt-healthcare/ui-toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import type { MutableRefObject } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { parse, set } from 'date-fns';

import debounce from 'lodash.debounce';
import { schema } from '../validationSchema';
import type { AddTaskProps, TaskFormData } from '../types';
import { TaskListFormWrapper } from '../styles';
import { buildTaskOption } from './utils';
import { TaskDueDateWrapper, ButtonWrapper } from './styles';
import { TaskListStatus, TaskListType } from 'services/graphql';
import { useTasks, useTasksDispatch } from 'context/tasks/TasksContext';
import { useAddOrUpdateTaskList } from 'hooks/useAddOrUpdateTaskList';
import { TaskListTypeMap } from 'mappings/enums';

export const taskTypes = Object.keys(TaskListTypeMap).map((val) =>
  buildTaskOption(val as TaskListType)
);

export const TaskListForm = ({ refetch }: AddTaskProps) => {
  const datePickerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { taskDetails, isEditTask } = useTasks();
  const tasksDispatch = useTasksDispatch();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [shouldApplyDate, setShouldApplyDate] = useState<boolean>(false);
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  const isDraftTask = useMemo(
    () => taskDetails?.status === TaskListStatus.Draft,
    [taskDetails?.status]
  );
  const isTestDraftTask = useMemo(
    () => isDraftTask && taskDetails?.type === TaskListType.SendTestInvite,
    [isDraftTask, taskDetails?.type]
  );
  const isOverdueDraftTask = useMemo(
    () => isDraftTask && taskDetails?.dueType === 'overdue',
    [isDraftTask, taskDetails?.dueType]
  );
  const isResetDate = useMemo(
    () => isTestDraftTask || isOverdueDraftTask,
    [isTestDraftTask, isOverdueDraftTask]
  );

  const {
    control,
    handleSubmit,
    register,
    watch,
    reset,
    setValue,
    trigger,
    formState: { errors, isValid },
  } = useForm<TaskFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema()),
    defaultValues: {
      task: null,
      taskDueDate: null,
      other: null,
    },
  });
  const watchTaskSelection = watch('task');
  const resetForm = () => {
    reset();
    tasksDispatch({ type: 'resetTaskDetails' });
    tasksDispatch({ type: 'resetIsEditTask' });
    document
      .querySelector("div[data-testid='task-list-panel']")
      ?.setAttribute('style', 'overflow: scroll;');
    document
      .getElementById('tasks-list')
      ?.setAttribute('style', 'opacity: 1; pointer-events: auto;');
    setSelectedDate(null);
    setIsCalendarOpen(false);
    setShouldApplyDate(false);
  };

  const { onSubmit, loading } = useAddOrUpdateTaskList(() => {
    resetForm();
    refetch?.();
  });

  const handleClickOutside = () => {
    setIsCalendarOpen(false);

    if (shouldApplyDate) return;
    if (isResetDate) return;

    setSelectedDate(
      taskDetails?.dueDateTime
        ? parse(taskDetails?.dueDateTime, 'dd/MM/yy', new Date())
        : null
    );
  };

  const handleApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShouldApplyDate(true);
    setValue('taskDueDate', selectedDate);
    setIsCalendarOpen(false);
  };

  useEffect(() => {
    if (isEditTask && taskDetails) {
      setShouldApplyDate(isDraftTask && !isResetDate);
      document.getElementById('add-task')?.scrollIntoView({
        behavior: 'smooth',
      });
      document
        .querySelector("div[data-testid='task-list-panel']")
        ?.setAttribute('style', 'overflow: hidden;');
      document
        .getElementById('tasks-list')
        ?.setAttribute('style', 'opacity: 20%; pointer-events: none;');

      setValue('task', buildTaskOption(taskDetails?.type));
      if (!isResetDate) {
        setSelectedDate(
          taskDetails?.dueDateTime
            ? parse(taskDetails?.dueDateTime, 'dd/MM/yy', new Date())
            : null
        );
        setValue(
          'taskDueDate',
          parse(taskDetails?.dueDateTime, 'dd/MM/yy', new Date())
        );
      }
      setValue(
        'other',
        taskDetails?.type === TaskListType.Other ? taskDetails?.text : null
      );
      if (isDraftTask) {
        setValue('status', TaskListStatus.Pending);
      }
      if (isDraftTask && !isResetDate) {
        trigger();
      }
    }
  }, [isEditTask, taskDetails, isDraftTask, isResetDate]);

  useEffect(() => {
    if (watchTaskSelection?.value !== TaskListType.Other) {
      setValue('other', null);
    }
  }, [watchTaskSelection]);

  useClickOutside(datePickerRef, handleClickOutside);

  return (
    <TaskListFormWrapper id="add-task" data-testid="add-task">
      <Controller
        name="task"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <DropDown
            id="taskOption"
            data-testid="taskOption"
            name="task"
            label="Task"
            options={taskTypes}
            value={value as TypedOptionType<TaskListType>}
            onBlur={onBlur}
            onChange={debounce(onChange)}
            errorText={errors.task?.message?.toString()}
            disabled={!!isEditTask && !isDraftTask}
          />
        )}
      />
      {watchTaskSelection?.value === TaskListType.Other && (
        <>
          <Spacer size="s2" />
          <Input
            id="other"
            label="Other"
            maxLength={30}
            {...register('other')}
            errorText={errors.other?.message?.toString()}
            disabled={!!isEditTask && !isDraftTask}
          />
        </>
      )}
      <Spacer size="s2" />
      <Controller
        name="taskDueDate"
        control={control}
        defaultValue={set(new Date(), {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })}
        render={({ field: { value, onChange, onBlur, name } }) => (
          <TaskDueDateWrapper
            data-testid="task-custom-date-picker"
            ref={datePickerRef}
            onClick={() => setIsCalendarOpen(true)}
          >
            <DatePicker
              id={name}
              onBlur={onBlur}
              label="Task due date"
              dateFormat="dd/MM/yy"
              selected={selectedDate || value}
              onChange={(val) => {
                setSelectedDate(val);
                onChange(val);
              }}
              name={name}
              minDate={new Date()}
              aria-label="Date input"
              placeholder="DD/MM/YY"
              disabledKeyboardNavigation
              showPreviousMonthsDays={false}
              transparentBackground
              shouldCloseOnSelect={false}
              isCalendarOpen={isCalendarOpen}
              iconColor={colors.primaryIndigo.indigo08}
              errorText={errors?.taskDueDate?.message?.toString()}
            >
              <ButtonWrapper>
                <Button
                  id="apply-custom-date-range-btn"
                  onClick={handleApply}
                  disabled={!selectedDate || !value}
                >
                  <Text fontSize={fontSizes.base} color={colors.base.white}>
                    Apply
                  </Text>
                </Button>
              </ButtonWrapper>
            </DatePicker>
          </TaskDueDateWrapper>
        )}
      />
      <Spacer size="s4" />
      <Button
        id="add-task-button"
        variant="primary"
        type="button"
        onClick={handleSubmit(onSubmit)}
        isLoading={loading}
        disabled={!shouldApplyDate || !isValid}
      >
        {!isDraftTask && isEditTask ? 'Confirm changes' : 'Submit'}
      </Button>
      {isEditTask && (
        <>
          <Spacer size="s4" />
          <Button
            id="cancel-edit-task-button"
            variant="secondary"
            type="button"
            onClick={resetForm}
            disabled={loading}
          >
            Cancel
          </Button>
        </>
      )}
    </TaskListFormWrapper>
  );
};
