import { colors, device, spacing } from '@bt-healthcare/ui-toolkit';
import styled from 'styled-components';
import { DatePickerWrapper } from 'components/DateRangePicker/styles';

export const TaskDueDateWrapper = styled(DatePickerWrapper)`
  position: relative;

  .react-datepicker-popper,
  .react-datepicker {
    width: 100%;
  }

  .react-datepicker {
    border-radius: ${spacing.s2};
    border-color: ${colors.grey.grey03};
    padding: 16px 12px;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__day-names {
    margin-bottom: 2px;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: 22px;
  }

  @media ${device.desktop} {
    .react-datepicker__day-names,
    .react-datepicker__week {
      display: flex;
      justify-content: space-evenly;
    }
  }
`;

export const ButtonWrapper = styled.div`
  padding: ${spacing.s3} ${spacing.s3} ${spacing.s1} ${spacing.s3};
`;
