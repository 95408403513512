import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: string;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: string;
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  ISO8601Duration: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AimHumanName = {
  __typename?: 'AIMHumanName';
  family: Maybe<Scalars['String']>;
  given: Maybe<Scalars['String']>;
};

export type AimHumanNameInput = {
  family: InputMaybe<Scalars['String']>;
  given: InputMaybe<Scalars['String']>;
};

export type AbsoluteAverages = {
  __typename?: 'AbsoluteAverages';
  averageValue: Maybe<Scalars['Float']>;
  res_averageValueInteger: Maybe<Scalars['Int']>;
};

export type ActivityPerformed = {
  __typename?: 'ActivityPerformed';
  /** Native app code system */
  codeSystem: Scalars['String'];
  /** Native app code value */
  codeValue: Scalars['String'];
  /** Enum string value of Activity mapping */
  display: Scalars['String'];
  /** Convert to UTC value of device local time */
  end: Scalars['DateTime'];
  /** Convert to minutes from native unit */
  mm: Scalars['Int'];
  /** Convert to UTC value of device local time */
  start: Scalars['DateTime'];
  /** Enum value of Activity mapping */
  type: Scalars['String'];
};

export type AddRegisterCareSettingClinicianInput = {
  careSettingAdministrator: Scalars['Boolean'];
  careSettingId: Scalars['ID'];
  defaultWardId: Scalars['ID'];
  emailAddress: Scalars['String'];
  firstName: Scalars['String'];
  mobileNumber: Scalars['String'];
  modules: Array<ModuleIdentifier>;
  surname: Scalars['String'];
};

export type AddWardPatientTaskInput = {
  careSettingId: Scalars['ID'];
  dueDateTime: Scalars['String'];
  personId: Scalars['ID'];
  wardPatientId: Scalars['ID'];
  wardPatientTaskStatus: TaskListStatus;
  wardPatientTaskType: TaskListType;
  wardPatientTaskTypeDetail: InputMaybe<Scalars['String']>;
};

export enum AdmissionStatus {
  Accepted = 'ACCEPTED',
  Invited = 'INVITED',
  InvitedStepup = 'INVITED_STEPUP',
  Monitoring = 'MONITORING',
}

export type ApplicableGuidelines = {
  __typename?: 'ApplicableGuidelines';
  guideline: Maybe<GuidelineType>;
  guidelineRules: Maybe<Array<Maybe<GuidelineRules>>>;
  guidelineState: Maybe<Scalars['Boolean']>;
};

export enum AssessmentType {
  Consultation = 'CONSULTATION',
  Review = 'REVIEW',
}

export type Audit = {
  __typename?: 'Audit';
  created: Maybe<Scalars['DateTime']>;
  createdBy: Maybe<Scalars['ID']>;
  createdByUser: Maybe<UserProfile>;
  lastUpdated: Maybe<Scalars['DateTime']>;
  updatedBy: Maybe<Scalars['ID']>;
  updatedByUser: Maybe<UserProfile>;
  version: Scalars['Int'];
};

export enum AwaitingFromDischargeAssess {
  AgainstMedicalAdvice = 'AGAINST_MEDICAL_ADVICE',
  AssessmentToBeCompleted = 'ASSESSMENT_TO_BE_COMPLETED',
  CarePackageAtHomePathway_1 = 'CARE_PACKAGE_AT_HOME_PATHWAY_1',
  ComplexCarePlacementPathway_3 = 'COMPLEX_CARE_PLACEMENT_PATHWAY_3',
  EndOfLifePathway_1 = 'END_OF_LIFE_PATHWAY_1',
  EquipmentAtHomePathway_1 = 'EQUIPMENT_AT_HOME_PATHWAY_1',
  IntermediateCarePathway_2 = 'INTERMEDIATE_CARE_PATHWAY_2',
  None = 'NONE',
  PatientDeath = 'PATIENT_DEATH',
  SimpleDischargePathway_0 = 'SIMPLE_DISCHARGE_PATHWAY_0',
}

export enum BedCleaningMethod {
  FogClean = 'FOG_CLEAN',
  InfectionControlClean = 'INFECTION_CONTROL_CLEAN',
  StandardClean = 'STANDARD_CLEAN',
}

export enum BedCleaningStatus {
  NotRequired = 'NOT_REQUIRED',
  Required = 'REQUIRED',
}

export type BloodPressure = RatedObservation & {
  __typename?: 'BloodPressure';
  /** The diastolic blood pressure */
  diastolic: Scalars['Int'];
  /** Comes from FHIR interpretation */
  diastolicRating: Maybe<Scalars['String']>;
  /** Comes from FHIR interpretation, or resolved lazily using assess observation */
  fhirRating: FhirRating;
  /**
   * Comes from FHIR interpretation, or resolved lazily using assess observation
   * @deprecated Use fhirRating
   */
  rating: Scalars['String'];
  /** The systolic blood pressure */
  systolic: Scalars['Int'];
  /** Comes from FHIR interpretation */
  systolicRating: Maybe<Scalars['String']>;
};

export type BloodPressureMedication = {
  __typename?: 'BloodPressureMedication';
  created: Scalars['DateTime'];
  hadBPMedication: Scalars['Boolean'];
  hasBPMedication: Scalars['Boolean'];
};

export type BodyHeight = {
  __typename?: 'BodyHeight';
  height: Scalars['Float'];
  heightUnit: BodyHeightUnit;
};

/** Unit for metric or imperial system */
export enum BodyHeightUnit {
  Cm = 'cm',
  Inch = 'inch',
}

export type BodyTemperature = {
  __typename?: 'BodyTemperature';
  celsius: Scalars['Float'];
};

export type BodyWeight = {
  __typename?: 'BodyWeight';
  /**
   * Metric system
   * @deprecated Use weight + weightUnit
   */
  kg: Scalars['Float'];
  /**
   * Imperial system
   * @deprecated Use weight + weightUnit
   */
  lb: Scalars['Float'];
  /** @deprecated Use weightUnit */
  userInputUnit: BodyWeightUnitInput;
  /** Weight value */
  weight: Scalars['Float'];
  weightUnit: BodyWeightUnitInput;
};

/** Unit for metric or imperial system */
export enum BodyWeightUnitInput {
  Kg = 'kg',
  Lb = 'lb',
}

export enum CarePackageStatusType {
  Complete = 'COMPLETE',
  Delayed = 'DELAYED',
  Incomplete = 'INCOMPLETE',
  None = 'NONE',
  NotRequired = 'NOT_REQUIRED',
}

export type CareSetting = Identifier & {
  __typename?: 'CareSetting';
  attributes: CareSettingAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingAttributes = {
  __typename?: 'CareSettingAttributes';
  contactPoints: Maybe<Array<Maybe<ContactPoint>>>;
  description: Scalars['String'];
  email: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** A list of related organisations such as General practices for a PCN */
  relatedOrganisations: Maybe<Array<Maybe<RelatedOrganisation>>>;
  res_phone: Maybe<Scalars['String']>;
  shortName: Scalars['String'];
};

export type CareSettingClinician = Identifier & {
  __typename?: 'CareSettingClinician';
  attributes: CareSettingClinicianAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingClinicianAttributes = {
  __typename?: 'CareSettingClinicianAttributes';
  audit: Maybe<Audit>;
  careSettingClinicianModuleAvailability: Array<Maybe<UserModuleAvailability>>;
  careSettingClinicianModuleRoles: Array<Maybe<UserModuleRole>>;
  careSettingClinicianUser: Maybe<User>;
  careSettingId: Scalars['ID'];
  /** @deprecated Use userId */
  clinicianId: Maybe<Scalars['ID']>;
  /** @deprecated Review usage with contactPoints */
  contactInformation: Maybe<CareSettingClinicianAttributesContactInformation>;
  contactPoints: Maybe<Array<Maybe<ContactPoint>>>;
  displayName: Maybe<Scalars['String']>;
  /** @deprecated Use displayName */
  dnHumanNameTextCurrent: Maybe<Scalars['String']>;
  /** @deprecated Use res_mobileNumber */
  dnPhoneNumberCurrent: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  humanNames: Maybe<Array<Maybe<HumanName>>>;
  res_mobileNumber: Maybe<Scalars['String']>;
  specialties: Maybe<Array<Maybe<SpecialtyType>>>;
  surname: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type CareSettingClinicianAttributesContactInformation = {
  __typename?: 'CareSettingClinicianAttributesContactInformation';
  primaryContactNumber: Maybe<Scalars['String']>;
  primaryEmailAddress: Maybe<Scalars['String']>;
};

export type CareSettingClinicianDocument = {
  __typename?: 'CareSettingClinicianDocument';
  data: CareSettingClinician;
};

export type CareSettingClinicianRegistration = Identifier & {
  __typename?: 'CareSettingClinicianRegistration';
  attributes: CareSettingClinicianRegistrationAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingClinicianRegistrationAttributes = {
  __typename?: 'CareSettingClinicianRegistrationAttributes';
  audit: Audit;
  careSettingClinician: CareSettingClinicianDocument;
  moduleRoles: Array<ModuleRole>;
  user: UserDocument;
};

export type CareSettingModule = Identifier & {
  __typename?: 'CareSettingModule';
  attributes: CareSettingModuleAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingModuleAttributes = {
  __typename?: 'CareSettingModuleAttributes';
  availabilityStatus: UserModuleAvailabilityStatus;
  careSettingId: Scalars['ID'];
  moduleId: ModuleIdentifier;
};

export type CareSettingPatient = Identifier & {
  __typename?: 'CareSettingPatient';
  attributes: CareSettingPatientAttributes;
  id: Scalars['ID'];
  person: Person;
  type: Scalars['String'];
};

export type CareSettingPatientAdmission = Identifier & {
  __typename?: 'CareSettingPatientAdmission';
  attributes: CareSettingPatientAdmissionAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type CareSettingPatientAdmissionAttributes = {
  __typename?: 'CareSettingPatientAdmissionAttributes';
  careSettingPatient: Maybe<CareSettingPatientDocument>;
};

export type CareSettingPatientAdmissionInput = {
  careSettingPatient: CareSettingPatientInput;
  person: PersonInput;
  personId: Scalars['ID'];
  user: InputMaybe<UserInput>;
};

export type CareSettingPatientAttributes = {
  __typename?: 'CareSettingPatientAttributes';
  admissionComments: Maybe<Scalars['String']>;
  admissionStatus: Maybe<AdmissionStatus>;
  admissionStatusUpdateDateTime: Maybe<Scalars['DateTime']>;
  audit: Maybe<Audit>;
  careSettingId: Maybe<Scalars['ID']>;
  decisionToAdmitDateTime: Maybe<Scalars['DateTime']>;
  decisionToAdmitWaitTime: Maybe<Scalars['Int']>;
  furtherWardPreferences: Maybe<Scalars['String']>;
  isolationType: IsolationType;
  isolationTypeOther: Maybe<Scalars['String']>;
  origin: Maybe<OriginType>;
  patientWardPreference: Maybe<PatientWardPreferencesType>;
  personId: Scalars['ID'];
  requiresIsolationYN: Maybe<YesNoType>;
  socialCareYN: Maybe<YesNoType>;
  specialty: Maybe<SpecialtyType>;
};

export type CareSettingPatientDocument = {
  __typename?: 'CareSettingPatientDocument';
  data: CareSettingPatient;
};

export type CareSettingPatientInput = {
  admissionComments: InputMaybe<Scalars['String']>;
  careSettingId: Scalars['ID'];
  decisionToAdmitDateTime: InputMaybe<Scalars['DateTime']>;
  furtherWardPreferences: InputMaybe<Scalars['String']>;
  isolationType: IsolationType;
  isolationTypeOther: InputMaybe<Scalars['String']>;
  origin: InputMaybe<OriginType>;
  patientWardPreference: InputMaybe<PatientWardPreferencesType>;
  requiresIsolationYN: InputMaybe<YesNoType>;
  socialCareYN: InputMaybe<YesNoType>;
  specialty: InputMaybe<SpecialtyType>;
};

export type CareSettingStepUpInvitationAttributes = {
  __typename?: 'CareSettingStepUpInvitationAttributes';
  address: Maybe<Scalars['String']>;
  careSettingId: Maybe<Scalars['ID']>;
  ethnicity: Maybe<Ethnicity>;
  gender: Maybe<StepUpGender>;
  medicalCentreReferenceId: Maybe<Scalars['String']>;
  monitoredConditions: Maybe<Array<Maybe<MonitoredCondition>>>;
  nhsNumber: Maybe<Scalars['String']>;
  observationTypes: Maybe<Array<Maybe<ObservationType>>>;
  personId: Maybe<Scalars['ID']>;
  phoneNumber: Maybe<Scalars['String']>;
  postcode: Maybe<Scalars['String']>;
  wardPatientId: Maybe<Scalars['ID']>;
};

export type CareSettingStepUpInvitationInput = {
  address: Scalars['String'];
  careSettingId: Scalars['ID'];
  ethnicity: Ethnicity;
  gender: StepUpGender;
  medicalCentreReferenceId: Scalars['String'];
  monitoredConditions: Array<MonitoredConditionInput>;
  nhsNumber: Scalars['String'];
  observationTypes: Array<ObservationType>;
  personId: Scalars['ID'];
  phoneNumber: Scalars['String'];
  postcode: Scalars['String'];
};

export type Cholesterol = {
  __typename?: 'Cholesterol';
  /** HDL cholesterol */
  hdl: Scalars['Float'];
  /** Total cholesterol */
  total: Scalars['Float'];
  unit: CholesterolUnit;
};

export enum CholesterolUnit {
  MilligramPerDeciliter = 'milligramPerDeciliter',
  MillimolePerLiter = 'millimolePerLiter',
}

export type Ckd = {
  __typename?: 'Ckd';
  ckdStatus: Scalars['Boolean'];
  created: Scalars['DateTime'];
};

export type ClinicianPatientLatestInformation = Identifier & {
  __typename?: 'ClinicianPatientLatestInformation';
  attributes: ClinicianPatientLatestInformationAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type ClinicianPatientLatestInformationAttributes = {
  __typename?: 'ClinicianPatientLatestInformationAttributes';
  age: Maybe<Scalars['Int']>;
  latestHealthInformation: Maybe<HealthInformation>;
  latestObservationTypes: Maybe<ClinicianPatientObservationTypeInformation>;
  personId: Scalars['ID'];
  res_bmiValue: Maybe<Scalars['Float']>;
  wardPatientId: Scalars['ID'];
};

export type ClinicianPatientObservationTypeInformation = {
  __typename?: 'ClinicianPatientObservationTypeInformation';
  fastingBloodGlucoseInformation: Maybe<ClinicianPatientObservationTypeMeasurement>;
  hdlCholesterolInformation: Maybe<ClinicianPatientObservationTypeMeasurement>;
  totalCholesterolInformation: Maybe<ClinicianPatientObservationTypeMeasurement>;
  weightInformation: Maybe<ClinicianPatientObservationTypeMeasurement>;
};

export type ClinicianPatientObservationTypeMeasurement = {
  __typename?: 'ClinicianPatientObservationTypeMeasurement';
  created: Scalars['DateTime'];
  unit: Scalars['String'];
  value: Scalars['Float'];
};

export enum CommentTypes {
  ChangedStatus = 'CHANGED_STATUS',
  ContactedFamilyCarer = 'CONTACTED_FAMILY_CARER',
  EmergencyAction = 'EMERGENCY_ACTION',
  Medication = 'MEDICATION',
  Observations = 'OBSERVATIONS',
  Other = 'OTHER',
  PatientContactedClinicianRequired = 'PATIENT_CONTACTED_CLINICIAN_REQUIRED',
  PatientContactedF2FRequired = 'PATIENT_CONTACTED_F2F_REQUIRED',
  PatientContactedNoClinicianRequired = 'PATIENT_CONTACTED_NO_CLINICIAN_REQUIRED',
}

export enum ConsultationTimeframe {
  OneMonth = 'ONE_MONTH',
  OneWeek = 'ONE_WEEK',
  Other = 'OTHER',
  SixMonths = 'SIX_MONTHS',
  ThreeMonths = 'THREE_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS',
  TwoWeeks = 'TWO_WEEKS',
  WithinTwoDays = 'WITHIN_TWO_DAYS',
}

export enum ContactExclusionPreferences {
  ContactexclAll = 'CONTACTEXCL_ALL',
  ContactexclEmailAdvise = 'CONTACTEXCL_EMAIL_ADVISE',
  ContactexclEmailAll = 'CONTACTEXCL_EMAIL_ALL',
  ContactexclEmailLatestNewsUpdates = 'CONTACTEXCL_EMAIL_LATEST_NEWS_UPDATES',
  ContactexclEmailReminder = 'CONTACTEXCL_EMAIL_REMINDER',
  ContactexclSmsApptReminderGp = 'CONTACTEXCL_SMS_APPT_REMINDER_GP',
}

export type ContactPoint = {
  __typename?: 'ContactPoint';
  period: Period;
  rank: Maybe<Scalars['Int']>;
  system: ContactPointsSystem;
  use: Maybe<ContactPointsUse>;
  value: Scalars['String'];
};

export enum ContactPointsSystem {
  Email = 'EMAIL',
  Fax = 'FAX',
  Other = 'OTHER',
  Pager = 'PAGER',
  Phone = 'PHONE',
  Sms = 'SMS',
  Url = 'URL',
}

export enum ContactPointsUse {
  Home = 'HOME',
  Mobile = 'MOBILE',
  Old = 'OLD',
  Temp = 'TEMP',
  Work = 'WORK',
}

export enum Covid19Status {
  ConfirmedNegative = 'CONFIRMED_NEGATIVE',
  ConfirmedPositive = 'CONFIRMED_POSITIVE',
  None = 'NONE',
  Quarantined = 'QUARANTINED',
  Symptomatic = 'SYMPTOMATIC',
}

export type Cvd = {
  __typename?: 'Cvd';
  created: Scalars['DateTime'];
  familyHadCvd: CvdFamilyInformation;
  hasCvd: Scalars['Boolean'];
};

export enum CvdFamilyInformation {
  DontKnow = 'DONT_KNOW',
  No = 'NO',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  Yes = 'YES',
}

export type DailySitting = {
  __typename?: 'DailySitting';
  /** Date of sitting hours */
  dateTime: Scalars['DateTime'];
  /** Derived sitting hours */
  hours: Scalars['Int'];
};

export type DailyStepCount = {
  __typename?: 'DailyStepCount';
  /** Date of daily steps */
  dateTime: Scalars['DateTime'];
  /** Step count value */
  steps: Scalars['Int'];
};

export type Diabetes = {
  __typename?: 'Diabetes';
  created: Scalars['DateTime'];
  diabetesStatus: DiabetesStatus;
};

export enum DiabetesStatus {
  No = 'NO',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  Type_1 = 'TYPE_1',
  Type_2 = 'TYPE_2',
}

export enum DischargeReasonType {
  None = 'NONE',
  Other = 'OTHER',
  PatientAdmittedToHospital = 'PATIENT_ADMITTED_TO_HOSPITAL',
  PatientRefusedRemoteMonitoring = 'PATIENT_REFUSED_REMOTE_MONITORING',
  RemoteMonitoringCompleted = 'REMOTE_MONITORING_COMPLETED',
}

export enum DischargeStatusFailed {
  DelayedPharmacy = 'DELAYED_PHARMACY',
  DelayedPortering = 'DELAYED_PORTERING',
  DelayedTest = 'DELAYED_TEST',
  DelayedTransfer = 'DELAYED_TRANSFER',
  None = 'NONE',
  NoCarePackage = 'NO_CARE_PACKAGE',
  Other = 'OTHER',
  PatientChange = 'PATIENT_CHANGE',
}

export enum DischargeStatusType {
  ClinicallyReady = 'CLINICALLY_READY',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Delayed = 'DELAYED',
  None = 'NONE',
  NotFit = 'NOT_FIT',
  Potential = 'POTENTIAL',
}

export enum ErrorCode {
  AttributeNotSet = 'ATTRIBUTE_NOT_SET',
  Generic = 'GENERIC',
  MandatoryHeaderError = 'MANDATORY_HEADER_ERROR',
  NotFoundError = 'NOT_FOUND_ERROR',
  PasswordContainsUserInformation = 'PASSWORD_CONTAINS_USER_INFORMATION',
  PasswordTooCommon = 'PASSWORD_TOO_COMMON',
  PatientDischarged = 'PATIENT_DISCHARGED',
  RegisterGpPatientLinkage = 'REGISTER_GP_PATIENT_LINKAGE',
  Unauthorized = 'UNAUTHORIZED',
  UserAlreadyExists = 'USER_ALREADY_EXISTS',
  VersionMismatch = 'VERSION_MISMATCH',
}

export enum Ethnicity {
  AsianOrAsianBritishAnyOtherAsianBackground = 'ASIAN_OR_ASIAN_BRITISH_ANY_OTHER_ASIAN_BACKGROUND',
  AsianOrAsianBritishBangladeshi = 'ASIAN_OR_ASIAN_BRITISH_BANGLADESHI',
  AsianOrAsianBritishIndian = 'ASIAN_OR_ASIAN_BRITISH_INDIAN',
  AsianOrAsianBritishPakistani = 'ASIAN_OR_ASIAN_BRITISH_PAKISTANI',
  BlackOrBlackBritishAfrican = 'BLACK_OR_BLACK_BRITISH_AFRICAN',
  BlackOrBlackBritishAnyOtherBlackBackground = 'BLACK_OR_BLACK_BRITISH_ANY_OTHER_BLACK_BACKGROUND',
  BlackOrBlackBritishCaribbean = 'BLACK_OR_BLACK_BRITISH_CARIBBEAN',
  MixedAnyOtherMixed = 'MIXED_ANY_OTHER_MIXED',
  MixedWhiteAndAsian = 'MIXED_WHITE_AND_ASIAN',
  MixedWhiteAndBlackAfrican = 'MIXED_WHITE_AND_BLACK_AFRICAN',
  MixedWhiteAndBlackCaribbean = 'MIXED_WHITE_AND_BLACK_CARIBBEAN',
  NotKnown = 'NOT_KNOWN',
  NotStated = 'NOT_STATED',
  OtherEthnicGroupsAnyOtherEthnicGroup = 'OTHER_ETHNIC_GROUPS_ANY_OTHER_ETHNIC_GROUP',
  OtherEthnicGroupsChinese = 'OTHER_ETHNIC_GROUPS_CHINESE',
  WhiteAnyOtherWhiteBackground = 'WHITE_ANY_OTHER_WHITE_BACKGROUND',
  WhiteBritish = 'WHITE_BRITISH',
  WhiteIrish = 'WHITE_IRISH',
}

export enum FhirRating {
  CriticalHigh = 'CRITICAL_HIGH',
  CriticalLow = 'CRITICAL_LOW',
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL',
  SignificantlyHigh = 'SIGNIFICANTLY_HIGH',
}

export type FastingBloodGlucose = RatedObservation & {
  __typename?: 'FastingBloodGlucose';
  /** Fasting blood glucose */
  bloodGlucose: Scalars['Float'];
  fhirRating: FhirRating;
  /**
   * FHIR rating
   * @deprecated Use fhirRating
   */
  rating: Scalars['String'];
};

export enum GenderAssignedAtBirth {
  Female = 'FEMALE',
  Male = 'MALE',
}

export enum GenderIdentifiedAs {
  Female = 'FEMALE',
  Male = 'MALE',
  None = 'NONE',
  NonBinary = 'NON_BINARY',
  Other = 'OTHER',
  PreferNone = 'PREFER_NONE',
  Transman = 'TRANSMAN',
  Transwoman = 'TRANSWOMAN',
}

export type GuidelineApplicabilityResult = {
  __typename?: 'GuidelineApplicabilityResult';
  applicableGuidelines: Maybe<Array<Maybe<ApplicableGuidelines>>>;
};

export type GuidelineApplicabilitySearchResult = Identifier & {
  __typename?: 'GuidelineApplicabilitySearchResult';
  attributes: GuidelineApplicabilitySearchResultAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type GuidelineApplicabilitySearchResultAttributes = {
  __typename?: 'GuidelineApplicabilitySearchResultAttributes';
  guidelineApplicabilityResult: Maybe<GuidelineApplicabilityResult>;
};

export enum GuidelineDeclinedReason {
  Contraindicated = 'CONTRAINDICATED',
  DrugAllergies = 'DRUG_ALLERGIES',
  DrugDrugInteraction = 'DRUG_DRUG_INTERACTION',
  OnMaximalToleratedTherapy = 'ON_MAXIMAL_TOLERATED_THERAPY',
  Other = 'OTHER',
  PastMedicationTrialsUnsuccessful = 'PAST_MEDICATION_TRIALS_UNSUCCESSFUL',
}

export type GuidelineRecommendationDecisions = {
  __typename?: 'GuidelineRecommendationDecisions';
  guidelineAccepted: YesNoType;
  guidelineRecommended: GuidelineType;
  otherRecommendationDeclinedReasonComments: Maybe<Scalars['String']>;
  recommendationDeclinedReason: Maybe<GuidelineDeclinedReason>;
};

export type GuidelineRecommendationDecisionsInput = {
  guidelineAccepted: YesNoType;
  guidelineRecommended: GuidelineType;
  otherRecommendationDeclinedReasonComments: InputMaybe<Scalars['String']>;
  recommendationDeclinedReason: InputMaybe<GuidelineDeclinedReason>;
};

export type GuidelineRules = {
  __typename?: 'GuidelineRules';
  ruleId: Maybe<GuidelineRulesType>;
  ruleState: Maybe<Scalars['Boolean']>;
};

export enum GuidelineRulesType {
  DiastolicAvgGtOrEq_95 = 'DIASTOLIC_AVG_GT_OR_EQ_95',
  ObservationsCountGtOrEq_8 = 'OBSERVATIONS_COUNT_GT_OR_EQ_8',
  ObservationsDaysCountGtOrEq_4AndLtOrEq_28 = 'OBSERVATIONS_DAYS_COUNT_GT_OR_EQ_4_AND_LT_OR_EQ_28',
  SystolicAvgGtOrEq_150 = 'SYSTOLIC_AVG_GT_OR_EQ_150',
}

export enum GuidelineType {
  ConsiderMedication = 'CONSIDER_MEDICATION',
  DiscussMedication = 'DISCUSS_MEDICATION',
  LifestyleOnly = 'LIFESTYLE_ONLY',
  OfferMedication = 'OFFER_MEDICATION',
}

export type HealthInformation = {
  __typename?: 'HealthInformation';
  bloodPressureMedicationInformation: Maybe<BloodPressureMedication>;
  ckdInformation: Maybe<Ckd>;
  cvdInformation: Maybe<Cvd>;
  diabetesInformation: Maybe<Diabetes>;
  heightInformation: Maybe<Height>;
  smokingInformation: Maybe<Smoking>;
};

export type HeartRhythm = {
  __typename?: 'HeartRhythm';
  bpm: Scalars['Int'];
  interpretation: HeartRhythmInterpretation;
  symptoms: Maybe<Array<Scalars['String']>>;
};

export enum HeartRhythmInterpretation {
  Detected = 'Detected',
  Indeterminate = 'Indeterminate',
  NotDetected = 'NotDetected',
}

export type Height = {
  __typename?: 'Height';
  created: Scalars['DateTime'];
  unit: HeightUnit;
  value: Scalars['Float'];
};

export enum HeightUnit {
  Cm = 'cm',
  Inch = 'inch',
}

export type HumanName = {
  __typename?: 'HumanName';
  family: Maybe<Scalars['String']>;
  given: Maybe<Array<Maybe<Scalars['String']>>>;
  period: Period;
  prefix: Maybe<Array<Maybe<Scalars['String']>>>;
  suffix: Maybe<Array<Maybe<Scalars['String']>>>;
  text: Maybe<Scalars['String']>;
  use: HumanNamesUse;
};

export enum HumanNamesUse {
  Anonymous = 'ANONYMOUS',
  Maiden = 'MAIDEN',
  Nickname = 'NICKNAME',
  Official = 'OFFICIAL',
  Old = 'OLD',
  Temp = 'TEMP',
  Usual = 'USUAL',
}

export type HypertensionAssessment = {
  __typename?: 'HypertensionAssessment';
  averageDiastolic: Scalars['Int'];
  averageSystolic: Scalars['Int'];
  conditionTestTypeOther: Maybe<Scalars['String']>;
  conditionTestTypes: Maybe<Array<Maybe<TestType>>>;
  consultationRequiredYN: Maybe<YesNoType>;
  consultationTimeframe: Maybe<ConsultationTimeframe>;
  customConsultationTimeframe: Maybe<Scalars['String']>;
  fitToSelfMonitorYN: Maybe<YesNoType>;
  qriskScore: Maybe<Scalars['Float']>;
  qriskScoreVersion: Maybe<Scalars['Int']>;
  reasonForNoUrgentClinicalIntervention: Maybe<Scalars['String']>;
  testsRequiredYN: Maybe<YesNoType>;
  totalDays: Scalars['Int'];
  totalReadings: Scalars['Int'];
  urgentClinicalInterventionRequiredYN: Maybe<YesNoType>;
  urgentClinicalInterventionTakenPlaceYN: Maybe<YesNoType>;
};

export type HypertensionAssessmentInput = {
  averageDiastolic: Scalars['Int'];
  averageSystolic: Scalars['Int'];
  conditionTestTypeOther: InputMaybe<Scalars['String']>;
  conditionTestTypes: InputMaybe<Array<InputMaybe<TestType>>>;
  consultationRequiredYN: InputMaybe<YesNoType>;
  consultationTimeframe: InputMaybe<ConsultationTimeframe>;
  customConsultationTimeframe: InputMaybe<Scalars['String']>;
  fitToSelfMonitorYN: InputMaybe<YesNoType>;
  qriskScore: InputMaybe<Scalars['Float']>;
  /** QRISK version number. Latest version is 3 and will default to this value */
  qriskScoreVersion: InputMaybe<Scalars['Int']>;
  reasonForNoUrgentClinicalIntervention: InputMaybe<Scalars['String']>;
  testsRequiredYN: InputMaybe<YesNoType>;
  totalDays: Scalars['Int'];
  totalReadings: Scalars['Int'];
  urgentClinicalInterventionRequiredYN: InputMaybe<YesNoType>;
  urgentClinicalInterventionTakenPlaceYN: InputMaybe<YesNoType>;
};

export type HypertensionGuidelineApplicabilitySearchInput = {
  averageDiastolicValue: Scalars['Int'];
  averageSystolicValue: Scalars['Int'];
  totalNoOfDays: Scalars['Int'];
  totalNoOfReadings: Scalars['Int'];
};

export type Identifier = {
  id: Scalars['ID'];
  type: Scalars['String'];
};

export enum InformationSource {
  AppleHealth = 'APPLE_HEALTH',
  Clare = 'CLARE',
  GoogleHealthConnect = 'GOOGLE_HEALTH_CONNECT',
  Lifelight = 'LIFELIGHT',
  User = 'USER',
}

export type IntervalStepCount = {
  __typename?: 'IntervalStepCount';
  /** End datetime of steps interval */
  end: Scalars['DateTime'];
  /** Start datetime of steps interval */
  start: Scalars['DateTime'];
  /** Step count value */
  steps: Scalars['Int'];
};

export enum IsolationType {
  CommonCold = 'COMMON_COLD',
  Covid_19 = 'COVID_19',
  Dv = 'DV',
  Mrsa = 'MRSA',
  None = 'NONE',
  Other = 'OTHER',
  PatientAggressive = 'PATIENT_AGGRESSIVE',
  PatientConfused = 'PATIENT_CONFUSED',
}

export type MedicationAssessment = {
  __typename?: 'MedicationAssessment';
  /** Medication outcome comments */
  comments: Maybe<Scalars['String']>;
  guidelineRecommendationDecisions: Maybe<
    Array<Maybe<GuidelineRecommendationDecisions>>
  >;
  hasEverTakenBloodPressureMedicationYN: Maybe<YesNoType>;
  isTakingBloodPressureMedicationYN: Maybe<YesNoType>;
  medicationOutcome: Maybe<MedicationOutcome>;
  medicationProtocol: Maybe<MedicationProtocol>;
  newMedicationProtocol: Maybe<MedicationProtocol>;
  stoppedMedicationOutcomeComments: Maybe<Scalars['String']>;
};

export type MedicationAssessmentInput = {
  /** Medication outcome comments */
  comments: InputMaybe<Scalars['String']>;
  guidelineRecommendationDecisions: InputMaybe<
    Array<InputMaybe<GuidelineRecommendationDecisionsInput>>
  >;
  hasEverTakenBloodPressureMedicationYN: InputMaybe<YesNoType>;
  isTakingBloodPressureMedicationYN: InputMaybe<YesNoType>;
  medicationOutcome: InputMaybe<MedicationOutcome>;
  medicationProtocol: InputMaybe<MedicationProtocol>;
  newMedicationProtocol: InputMaybe<MedicationProtocol>;
  stoppedMedicationOutcomeComments: InputMaybe<Scalars['String']>;
};

export enum MedicationOutcome {
  Changed = 'CHANGED',
  Decreased = 'DECREASED',
  Increased = 'INCREASED',
  OnMaximalToleratedTherapy = 'ON_MAXIMAL_TOLERATED_THERAPY',
  Refused = 'REFUSED',
  SatisfactoryNoChanges = 'SATISFACTORY_NO_CHANGES',
  Started = 'STARTED',
  Stopped = 'STOPPED',
}

export enum MedicationProtocol {
  Double = 'DOUBLE',
  None = 'NONE',
  Quadruple = 'QUADRUPLE',
  Single = 'SINGLE',
  Triple = 'TRIPLE',
}

export enum Mobility {
  MaximumAssistance = 'MAXIMUM_ASSISTANCE',
  MinimumAssistance = 'MINIMUM_ASSISTANCE',
  ModerateAssistance = 'MODERATE_ASSISTANCE',
  NoAssistance = 'NO_ASSISTANCE',
}

export enum ModuleIdentifier {
  CommandCentre = 'COMMAND_CENTRE',
  HealthyyouClinician = 'HEALTHYYOU_CLINICIAN',
  HealthyyouPatient = 'HEALTHYYOU_PATIENT',
  SmartMessaging = 'SMART_MESSAGING',
}

export enum ModuleRole {
  CareSettingAdministrator = 'CARE_SETTING_ADMINISTRATOR',
  CommandCentreDefaultUser = 'COMMAND_CENTRE_DEFAULT_USER',
  HealthyyouClinicianDefaultUser = 'HEALTHYYOU_CLINICIAN_DEFAULT_USER',
  HealthyyouPatientDefaultUser = 'HEALTHYYOU_PATIENT_DEFAULT_USER',
  SmartMessagingDefaultUser = 'SMART_MESSAGING_DEFAULT_USER',
}

export type MonitoredCondition = {
  __typename?: 'MonitoredCondition';
  comments: Maybe<Scalars['String']>;
  condition: PatientDiagnosis;
  consultationStatus: Maybe<MonitoredConditionConsultationStatus>;
  dischargeStatus: Maybe<DischargeStatusType>;
  monitoringStatus: Maybe<MonitoringStatus>;
  /** @deprecated Replaced with monitoringStatus */
  period: Period;
  reasonForDischarge: Maybe<DischargeReasonType>;
  testStatus: Maybe<MonitoredConditionTestStatus>;
};

/** Dynamically generated schema type to extend status fields */
export type MonitoredConditionConsultationStatus = {
  __typename?: 'MonitoredConditionConsultationStatus';
  res_statusUpdatedBy: Maybe<Scalars['String']>;
  status: Maybe<MonitoredConditionConsultationStatusType>;
  statusUpdateDateTime: Maybe<Scalars['DateTime']>;
  statusUpdatedBy: Maybe<Scalars['ID']>;
};

export enum MonitoredConditionConsultationStatusType {
  Booked = 'BOOKED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Invited = 'INVITED',
  Missed = 'MISSED',
  NotRequired = 'NOT_REQUIRED',
  Required = 'REQUIRED',
}

export type MonitoredConditionInput = {
  condition: PatientDiagnosis;
  period: PeriodInput;
};

/** Dynamically generated schema type to extend status fields */
export type MonitoredConditionTestStatus = {
  __typename?: 'MonitoredConditionTestStatus';
  res_statusUpdatedBy: Maybe<Scalars['String']>;
  status: Maybe<MonitoredConditionTestStatusType>;
  statusUpdateDateTime: Maybe<Scalars['DateTime']>;
  statusUpdatedBy: Maybe<Scalars['ID']>;
};

export enum MonitoredConditionTestStatusType {
  AwaitingResults = 'AWAITING_RESULTS',
  Booked = 'BOOKED',
  Cancelled = 'CANCELLED',
  Invited = 'INVITED',
  Missed = 'MISSED',
  NotRequired = 'NOT_REQUIRED',
  Overdue = 'OVERDUE',
  Required = 'REQUIRED',
  Returned = 'RETURNED',
  Reviewed = 'REVIEWED',
}

/** Dynamically generated schema type to extend status fields */
export type MonitoringStatus = {
  __typename?: 'MonitoringStatus';
  res_statusUpdatedBy: Maybe<Scalars['String']>;
  status: Maybe<MonitoringStatusType>;
  statusUpdateDateTime: Maybe<Scalars['DateTime']>;
  statusUpdatedBy: Maybe<Scalars['ID']>;
};

export enum MonitoringStatusType {
  AwaitingReadings = 'AWAITING_READINGS',
  ReadingsAvailable = 'READINGS_AVAILABLE',
  ReadyForReview = 'READY_FOR_REVIEW',
  Reviewed = 'REVIEWED',
  SelfMonitoring = 'SELF_MONITORING',
  UrgentClinicalInterventionRequired = 'URGENT_CLINICAL_INTERVENTION_REQUIRED',
}

export type Mutation = {
  __typename?: 'Mutation';
  addRegisterCareSettingClinician: Maybe<CareSettingClinicianRegistration>;
  addWardPatientTask: Maybe<WardPatientTask>;
  admitCareSettingPatient: Maybe<CareSettingPatientAdmission>;
  careSettingStepUpInvitation: Maybe<CareSettingStepUpInvitationAttributes>;
  createWardDischarge: WardDischarge;
  createWardPatient: WardPatient;
  createWardPatientConditionAssessment: WardPatientConditionAssessment;
  patientStepup: Maybe<PatientStepupAttributes>;
  /** Executes a PUT operation on the Person resource, provided that the application of arguments result in modifications. */
  updatePerson: Maybe<Person>;
  updateRegisterCareSettingClinician: Maybe<CareSettingClinicianRegistration>;
  updateUserState: Maybe<User>;
  updateWardPatientAttributeComment: WardPatient;
  updateWardPatientConsultationStatus: WardPatient;
  updateWardPatientMonitoringStatus: WardPatient;
  updateWardPatientTask: Maybe<WardPatientTask>;
  updateWardPatientTestStatus: WardPatient;
  wardPatientComments: WardPatientComments;
};

export type MutationAddRegisterCareSettingClinicianArgs = {
  input: AddRegisterCareSettingClinicianInput;
};

export type MutationAddWardPatientTaskArgs = {
  input: InputMaybe<AddWardPatientTaskInput>;
};

export type MutationAdmitCareSettingPatientArgs = {
  input: CareSettingPatientAdmissionInput;
};

export type MutationCareSettingStepUpInvitationArgs = {
  input: CareSettingStepUpInvitationInput;
};

export type MutationCreateWardDischargeArgs = {
  input: WardDischargeInput;
};

export type MutationCreateWardPatientArgs = {
  input: WardPatientInput;
};

export type MutationCreateWardPatientConditionAssessmentArgs = {
  input: WardPatientConditionAssessmentInput;
};

export type MutationPatientStepupArgs = {
  input: InputMaybe<PatientStepupInput>;
};

export type MutationUpdatePersonArgs = {
  input: PersonInput;
  personId: Scalars['ID'];
};

export type MutationUpdateRegisterCareSettingClinicianArgs = {
  input: UpdateRegisterCareSettingClinicianInput;
};

export type MutationUpdateUserStateArgs = {
  input: UserStateInput;
};

export type MutationUpdateWardPatientAttributeCommentArgs = {
  input: WardPatientCommentsInput;
};

export type MutationUpdateWardPatientConsultationStatusArgs = {
  input: WardPatientUpdateConsultationStatusInput;
};

export type MutationUpdateWardPatientMonitoringStatusArgs = {
  input: WardPatientUpdateMonitoringStatusInput;
};

export type MutationUpdateWardPatientTaskArgs = {
  input: InputMaybe<UpdateWardPatientTaskInput>;
};

export type MutationUpdateWardPatientTestStatusArgs = {
  input: WardPatientUpdateTestStatusInput;
};

export type MutationWardPatientCommentsArgs = {
  input: WardPatientCommentsInput;
};

export enum ObservationType {
  ActivityPerformed = 'ACTIVITY_PERFORMED',
  All = 'ALL',
  AverageBloodPressure = 'AVERAGE_BLOOD_PRESSURE',
  BloodPressure = 'BLOOD_PRESSURE',
  Cholesterol = 'CHOLESTEROL',
  DailySitting = 'DAILY_SITTING',
  DailyStepCount = 'DAILY_STEP_COUNT',
  FastingBloodGlucose = 'FASTING_BLOOD_GLUCOSE',
  HeartRhythm = 'HEART_RHYTHM',
  Height = 'HEIGHT',
  IntervalStepCount = 'INTERVAL_STEP_COUNT',
  None = 'NONE',
  Pulse = 'PULSE',
  Respiratory = 'RESPIRATORY',
  SampleStepCount = 'SAMPLE_STEP_COUNT',
  SleepDuration = 'SLEEP_DURATION',
  Spo2 = 'SPO2',
  Temperature = 'TEMPERATURE',
  Weight = 'WEIGHT',
}

export type ObservationTypeReadings = {
  __typename?: 'ObservationTypeReadings';
  id: Scalars['ID'];
  observationReadings: Array<Maybe<ObservationsReadingsView>>;
  observationType: ObservationType;
  res_dailyAverage: Maybe<Scalars['String']>;
};

export type ObservationValue =
  | ActivityPerformed
  | BloodPressure
  | BodyHeight
  | BodyTemperature
  | BodyWeight
  | Cholesterol
  | DailySitting
  | DailyStepCount
  | FastingBloodGlucose
  | HeartRhythm
  | IntervalStepCount
  | Pulse
  | Respiratory
  | SampleStepCount
  | SleepDuration
  | Spo2;

export type ObservationVisualsSearchFilters = {
  endDate: Scalars['DateTime'];
  observationType: InputMaybe<ObservationType>;
  personId: Scalars['ID'];
  startDate: Scalars['DateTime'];
};

export type ObservationVisualsSearchInput = {
  filters: ObservationVisualsSearchFilters;
  size: InputMaybe<SearchResultsSizeInput>;
  sort: ObservationVisualsSortInput;
};

export type ObservationVisualsSortInput = {
  recordedDateTime: SortDirection;
};

export type ObservationVisualsValueItem = {
  __typename?: 'ObservationVisualsValueItem';
  name: Maybe<Scalars['String']>;
  value: Maybe<Scalars['Float']>;
};

export type ObservationVisualsValues = {
  __typename?: 'ObservationVisualsValues';
  values: Maybe<Array<Maybe<ObservationVisualsValueItem>>>;
};

export type ObservationVisualsView = {
  __typename?: 'ObservationVisualsView';
  id: Scalars['ID'];
  observationType: ObservationType;
  recordedDateTime: Scalars['DateTime'];
  res_observationValues: Maybe<ObservationVisualsValues>;
};

export type ObservationVisualsViewSearchResults = {
  __typename?: 'ObservationVisualsViewSearchResults';
  hits: Scalars['Int'];
  searchRequestId: Scalars['ID'];
  searchResults: Array<ObservationVisualsView>;
  timedOut: Scalars['Boolean'];
};

export type ObservationsAbsoluteAverage = Identifier & {
  __typename?: 'ObservationsAbsoluteAverage';
  attributes: ObservationsAbsoluteAverageAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type ObservationsAbsoluteAverageAttributes = {
  __typename?: 'ObservationsAbsoluteAverageAttributes';
  observationsAbsoluteAverages: Maybe<Array<ObservationsAbsoluteAverages>>;
  totalNoOfDays: Scalars['Int'];
  totalNoOfReadings: Scalars['Int'];
};

export type ObservationsAbsoluteAverages = {
  __typename?: 'ObservationsAbsoluteAverages';
  absoluteAverages: Maybe<Array<AbsoluteAverages>>;
  code: Maybe<Scalars['String']>;
  codeSchema: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Input to return an average value for an observation type over a specific date range - used to return the Average BP in HY-C */
export type ObservationsAverageInput = {
  from: Scalars['DateTime'];
  observationType: ObservationType;
  personId: Scalars['ID'];
  to: Scalars['DateTime'];
};

export type ObservationsForTodaysReadings = {
  __typename?: 'ObservationsForTodaysReadings';
  id: Scalars['ID'];
  observationTypeReadings: Array<Maybe<ObservationTypeReadings>>;
};

/** Input to return daily average for a list of observation types over a specific date range - includes all readings within the timeframe - used to return the Todays readings in HY-C */
export type ObservationsForTodaysReadingsInput = {
  from: Scalars['DateTime'];
  observationTypes: Array<InputMaybe<ObservationType>>;
  personId: Scalars['ID'];
  to: Scalars['DateTime'];
};

export type ObservationsReadingsView = {
  __typename?: 'ObservationsReadingsView';
  id: Scalars['ID'];
  recordedDateTime: Scalars['DateTime'];
  res_observationValues: Maybe<ObservationVisualsValues>;
};

export enum OriginType {
  AE = 'A_E',
  MedicalAssUnit = 'MEDICAL_ASS_UNIT',
  None = 'NONE',
  Outpatients = 'OUTPATIENTS',
  SurgicalAssUnit = 'SURGICAL_ASS_UNIT',
}

export enum PatientDiagnosis {
  Copd = 'COPD',
  Hypertension = 'HYPERTENSION',
  None = 'NONE',
}

export type PatientStepupAttributes = {
  __typename?: 'PatientStepupAttributes';
  careSettingId: Scalars['ID'];
  ethnicity: Ethnicity;
  medicalCentreReferenceId: Scalars['String'];
  monitoredConditions: Array<MonitoredCondition>;
  nhsNumber: Scalars['String'];
  observationTypes: Array<ObservationType>;
  personId: Scalars['ID'];
};

export type PatientStepupInput = {
  careSettingId: Scalars['ID'];
  ethnicity: Ethnicity;
  medicalCentreReferenceId: Scalars['String'];
  monitoredConditions: Array<MonitoredConditionInput>;
  nhsNumber: Scalars['String'];
  observationTypes: Array<ObservationType>;
  personId: Scalars['ID'];
};

export enum PatientWardPreferencesType {
  FemaleWard = 'FEMALE_WARD',
  GenderNeutral = 'GENDER_NEUTRAL',
  MaleWard = 'MALE_WARD',
  None = 'NONE',
}

export type Period = {
  __typename?: 'Period';
  end: Maybe<Scalars['DateTime']>;
  start: Maybe<Scalars['DateTime']>;
};

export type PeriodInput = {
  end: InputMaybe<Scalars['DateTime']>;
  start: InputMaybe<Scalars['DateTime']>;
};

export type Person = Identifier & {
  __typename?: 'Person';
  attributes: PersonAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type PersonAttributes = {
  __typename?: 'PersonAttributes';
  audit: Maybe<Audit>;
  careSettingId: Maybe<Scalars['ID']>;
  contactExclusionPreferences: Maybe<Array<Maybe<ContactExclusionPreferences>>>;
  contactInformation: Maybe<PersonContactInformation>;
  contactParty: Maybe<Array<Maybe<PersonContactPartyFields>>>;
  generalPracticeRegistration: Maybe<PersonGeneralPracticeRegistration>;
  /** @deprecated Replaced with latestHealthInformation */
  healthInformationLatest: Maybe<HealthInformation>;
  /** Unique hospital reference to identify a patient e.g. for ESNEFT this is the hospital no. in the format of D1234567 */
  hospitalPatientReference: Maybe<Scalars['String']>;
  latestHealthInformation: Maybe<HealthInformation>;
  personalInformation: PersonPersonalInformation;
  registeredGPSurgery: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type PersonContactInformation = {
  __typename?: 'PersonContactInformation';
  primaryContactNumber: Maybe<Scalars['String']>;
  primaryEmailAddress: Maybe<Scalars['String']>;
};

export type PersonContactInformationInput = {
  primaryContactNumber: InputMaybe<Scalars['String']>;
  primaryEmailAddress: InputMaybe<Scalars['String']>;
};

export type PersonContactPartyFields = {
  __typename?: 'PersonContactPartyFields';
  humanNames: Maybe<Array<Maybe<AimHumanName>>>;
  personRelationshipType: Maybe<PersonRelationshipType>;
  primaryContactNumber: Maybe<Scalars['String']>;
};

export type PersonContactPartyInput = {
  humanNames: InputMaybe<Array<InputMaybe<AimHumanNameInput>>>;
  personRelationshipType: PersonRelationshipType;
  primaryContactNumber: InputMaybe<Scalars['String']>;
};

export type PersonGeneralPracticeRegistration = {
  __typename?: 'PersonGeneralPracticeRegistration';
  accountId: Scalars['ID'];
  linkageKey: Scalars['String'];
  odsCode: Scalars['String'];
  patientIdentifier: Scalars['ID'];
};

export type PersonInput = {
  careSettingId: InputMaybe<Scalars['ID']>;
  contactInformation: InputMaybe<PersonContactInformationInput>;
  contactParty: InputMaybe<Array<InputMaybe<PersonContactPartyInput>>>;
  personalInformation: PersonPersonalInformationInput;
  registeredGPSurgery: InputMaybe<Scalars['String']>;
};

export type PersonPersonalInformation = {
  __typename?: 'PersonPersonalInformation';
  dateOfBirth: Maybe<Scalars['Date']>;
  ethnicity: Maybe<Ethnicity>;
  firstName: Scalars['String'];
  genderAssignedAtBirth: Maybe<GenderAssignedAtBirth>;
  genderIdentifiedAs: GenderIdentifiedAs;
  mobility: Maybe<Mobility>;
  nhsNumber: Maybe<Scalars['String']>;
  primaryAddress: Maybe<PrimaryAddress>;
  pronouns: Maybe<Pronouns>;
  surname: Scalars['String'];
  title: Scalars['String'];
};

export type PersonPersonalInformationInput = {
  dateOfBirth: InputMaybe<Scalars['Date']>;
  ethnicity: InputMaybe<Ethnicity>;
  firstName: Scalars['String'];
  genderAssignedAtBirth: InputMaybe<GenderAssignedAtBirth>;
  genderIdentifiedAs: GenderIdentifiedAs;
  mobility: InputMaybe<Mobility>;
  nhsNumber: InputMaybe<Scalars['String']>;
  primaryAddress: InputMaybe<PrimaryAddressInput>;
  pronouns: InputMaybe<Pronouns>;
  surname: Scalars['String'];
  title: Scalars['String'];
};

export enum PersonRelationshipType {
  NextOfKin = 'NEXT_OF_KIN',
  None = 'NONE',
  Other = 'OTHER',
}

export enum PharmacyStatusType {
  Delayed = 'DELAYED',
  None = 'NONE',
  NotRequired = 'NOT_REQUIRED',
  Ordered = 'ORDERED',
  Received = 'RECEIVED',
}

export enum PorterStatusType {
  None = 'NONE',
  NotRequired = 'NOT_REQUIRED',
  Requested = 'REQUESTED',
}

export type PrimaryAddress = {
  __typename?: 'PrimaryAddress';
  line1: Maybe<Scalars['String']>;
  postalCode: Maybe<Scalars['String']>;
};

export type PrimaryAddressInput = {
  line1: InputMaybe<Scalars['String']>;
  postalCode: InputMaybe<Scalars['String']>;
};

export enum Pronouns {
  HeHim = 'HE_HIM',
  SheHer = 'SHE_HER',
  TheyThem = 'THEY_THEM',
}

export type Pulse = RatedObservation & {
  __typename?: 'Pulse';
  /** The beats per minute */
  bpm: Scalars['Int'];
  fhirRating: FhirRating;
  /**
   * FHIR rating
   * @deprecated Use fhirRating
   */
  rating: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  careSettingModules: Maybe<Array<CareSettingModule>>;
  careSettingPatient: Maybe<CareSettingPatient>;
  /** Custom GraphQL view that returns latest Health Information and defined observation types for use on HY Clinician for a patient */
  clinicianPatientLatestInformation: Maybe<ClinicianPatientLatestInformation>;
  hypertensionGuidelineApplicabilitySearchResult: GuidelineApplicabilitySearchResult;
  /** Used in HY-C to return observations values for chart usage. */
  observationVisualsSearch: ObservationVisualsViewSearchResults;
  /** Used in HY-C to return observation averages for an observation type within a given date range, */
  observationsAbsoluteAverage: Maybe<ObservationsAbsoluteAverage>;
  /** Used in HY-C to return observations for Todays readings with the daily average for each observation type. */
  observationsForTodaysReadings: Maybe<ObservationsForTodaysReadings>;
  registeredCareSettingClinicians: Maybe<Array<CareSettingClinician>>;
  searchPerson: Maybe<SearchPersonAttributes>;
  tasksByStatuses: Maybe<Array<Maybe<WardPatientTask>>>;
  userProfile: Maybe<UserProfile>;
  /** Return a wardPatient based on a wardPatientId. Only provide a careSettingId if you need to determine the care setting clinician user for Monitored statuses */
  wardPatient: Maybe<WardPatient>;
  wardPatientComments: Array<WardPatientComments>;
  wardPatientConditionAssessment: Array<WardPatientConditionAssessment>;
  /** @deprecated Use wardPatientsSearch */
  wardPatientsByWard: Maybe<Array<WardPatient>>;
  wardPatientsSearch: WardPatientSearchRecordSearchResults;
};

export type QueryCareSettingModulesArgs = {
  careSettingId: Scalars['ID'];
};

export type QueryCareSettingPatientArgs = {
  patientId: Scalars['ID'];
};

export type QueryClinicianPatientLatestInformationArgs = {
  personId: Scalars['ID'];
  wardPatientId: Scalars['ID'];
};

export type QueryHypertensionGuidelineApplicabilitySearchResultArgs = {
  input: HypertensionGuidelineApplicabilitySearchInput;
};

export type QueryObservationVisualsSearchArgs = {
  input: ObservationVisualsSearchInput;
};

export type QueryObservationsAbsoluteAverageArgs = {
  input: ObservationsAverageInput;
};

export type QueryObservationsForTodaysReadingsArgs = {
  input: ObservationsForTodaysReadingsInput;
};

export type QueryRegisteredCareSettingCliniciansArgs = {
  careSettingId: Scalars['ID'];
};

export type QuerySearchPersonArgs = {
  input: InputMaybe<SearchPersonInput>;
};

export type QueryTasksByStatusesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  statuses: InputMaybe<Array<TaskListStatus>>;
  wardPatientId: Scalars['ID'];
};

export type QueryUserProfileArgs = {
  safId: Scalars['String'];
};

export type QueryWardPatientArgs = {
  careSettingId: InputMaybe<Scalars['ID']>;
  wardPatientId: Scalars['ID'];
};

export type QueryWardPatientCommentsArgs = {
  wardPatientId: Scalars['ID'];
};

export type QueryWardPatientConditionAssessmentArgs = {
  careSettingId: Scalars['ID'];
  wardPatientId: Scalars['ID'];
};

export type QueryWardPatientsByWardArgs = {
  ascending: InputMaybe<Scalars['Boolean']>;
  sortKey: InputMaybe<WardPatientsSortKey>;
  wardId: Scalars['ID'];
};

export type QueryWardPatientsSearchArgs = {
  admissionStatuses: InputMaybe<Array<AdmissionStatus>>;
  ascending: InputMaybe<Scalars['Boolean']>;
  careSettingId: InputMaybe<Scalars['ID']>;
  consultationStatuses: InputMaybe<
    Array<MonitoredConditionConsultationStatusType>
  >;
  from: InputMaybe<Scalars['Int']>;
  limit: InputMaybe<Scalars['Int']>;
  monitoredConditions: InputMaybe<Array<PatientDiagnosis>>;
  monitoringStatuses: InputMaybe<Array<MonitoringStatusType>>;
  registeredGPSurgeries: InputMaybe<Array<Scalars['String']>>;
  search: InputMaybe<Scalars['String']>;
  sortKey: InputMaybe<WardPatientsSortKey>;
  taskDueTypes: InputMaybe<Array<TaskListType>>;
  testStatuses: InputMaybe<Array<MonitoredConditionTestStatusType>>;
  wardId: InputMaybe<Scalars['ID']>;
};

export type RatedObservation = {
  fhirRating: FhirRating;
  /**
   * FHIR rating
   * @deprecated Use fhirRating
   */
  rating: Scalars['String'];
};

export enum RegistrationStatus {
  Unverified = 'UNVERIFIED',
  Verified = 'VERIFIED',
}

export type RelatedOrganisation = {
  __typename?: 'RelatedOrganisation';
  externalReferenceId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  relationshipType: RelatedOrganisationRelationshipType;
  type: RelatedOrganisationType;
};

export enum RelatedOrganisationRelationshipType {
  None = 'NONE',
  Pcn = 'PCN',
}

export enum RelatedOrganisationType {
  GeneralPractice = 'GENERAL_PRACTICE',
  None = 'NONE',
}

export type Respiratory = RatedObservation & {
  __typename?: 'Respiratory';
  /** The breaths per minute */
  bpm: Scalars['Int'];
  fhirRating: FhirRating;
  /**
   * FHIR rating
   * @deprecated Use fhirRating
   */
  rating: Scalars['String'];
};

export type SampleStepCount = {
  __typename?: 'SampleStepCount';
  /** End datetime of steps interval */
  end: Scalars['DateTime'];
  /** Start datetime of steps interval */
  start: Scalars['DateTime'];
  /** Step count value */
  steps: Scalars['Int'];
};

export type SearchPersonAttributes = {
  __typename?: 'SearchPersonAttributes';
  careSettingId: Maybe<Scalars['ID']>;
  contactInformation: Maybe<PersonContactInformation>;
  isStandalonePatient: Scalars['Boolean'];
  personId: Scalars['ID'];
  personalInformation: PersonPersonalInformation;
  userId: Scalars['ID'];
};

export type SearchPersonInput = {
  primaryEmailAddress: Scalars['String'];
};

export type SearchResultsSizeInput = {
  limit: InputMaybe<Scalars['Int']>;
  offset: InputMaybe<Scalars['Int']>;
};

export type SleepDuration = {
  __typename?: 'SleepDuration';
  /** End datetime of steps interval */
  end: Scalars['DateTime'];
  /** Sleep duration to nearest hour */
  hours: Scalars['Int'];
  /** Start datetime of steps interval */
  start: Scalars['DateTime'];
};

export type Smoking = {
  __typename?: 'Smoking';
  created: Scalars['DateTime'];
  status: SmokingStatus;
};

/** Used in health information */
export enum SmokingStatus {
  NoNeverSmoked = 'NO_NEVER_SMOKED',
  NoQuit = 'NO_QUIT',
  PreferNotSay = 'PREFER_NOT_SAY',
  YesLessThan_10ADay = 'YES_LESS_THAN_10_A_DAY',
  YesLessThan_20ADay = 'YES_LESS_THAN_20_A_DAY',
  YesMoreThan_20ADay = 'YES_MORE_THAN_20_A_DAY',
}

export enum SortDirection {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export enum SpecialtyType {
  Cardiology = 'CARDIOLOGY',
  EarNoseAndThroat = 'EAR_NOSE_AND_THROAT',
  EndocDiabetes = 'ENDOC_DIABETES',
  Gastro = 'GASTRO',
  GeniMedicine = 'GENI_MEDICINE',
  GenIntMedicine = 'GEN_INT_MEDICINE',
  GenSurgery = 'GEN_SURGERY',
  GeriMedicine = 'GERI_MEDICINE',
  MaxFax = 'MAX_FAX',
  Neurology = 'NEUROLOGY',
  None = 'NONE',
  Ortho = 'ORTHO',
  PlasticSurgery = 'PLASTIC_SURGERY',
  RenMedicine = 'REN_MEDICINE',
  RespMedicine = 'RESP_MEDICINE',
  Rhreu = 'RHREU',
  StrokeMedicine = 'STROKE_MEDICINE',
  Urology = 'UROLOGY',
  VascSurgery = 'VASC_SURGERY',
}

export type Spo2 = RatedObservation & {
  __typename?: 'Spo2';
  fhirRating: FhirRating;
  /** The oxygen saturation percentage */
  oxygenSaturation: Scalars['Int'];
  /**
   * FHIR rating
   * @deprecated Use fhirRating
   */
  rating: Scalars['String'];
};

export enum StepUpGender {
  Female = 'FEMALE',
  Indeterminate = 'INDETERMINATE',
  Male = 'MALE',
  None = 'NONE',
  NotKnown = 'NOT_KNOWN',
}

export enum TtoStatusType {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
  None = 'NONE',
}

export type TaskListAttributes = {
  __typename?: 'TaskListAttributes';
  audit: Maybe<Audit>;
  careSettingId: Scalars['ID'];
  completedDateTime: Maybe<Scalars['String']>;
  dueDateTime: Maybe<Scalars['String']>;
  isDeleted: Maybe<Scalars['Boolean']>;
  personId: Maybe<Scalars['ID']>;
  taskContext: Maybe<Scalars['String']>;
  wardPatientId: Maybe<Scalars['ID']>;
  wardPatientTaskStatus: Maybe<TaskListStatus>;
  wardPatientTaskType: Maybe<TaskListType>;
  wardPatientTaskTypeDetail: Maybe<Scalars['String']>;
};

export enum TaskListStatus {
  Complete = 'COMPLETE',
  Dismissed = 'DISMISSED',
  Draft = 'DRAFT',
  Pending = 'PENDING',
}

export enum TaskListType {
  ConfirmConsultationAttended = 'CONFIRM_CONSULTATION_ATTENDED',
  ConfirmConsultationBooked = 'CONFIRM_CONSULTATION_BOOKED',
  ConfirmTestAttended = 'CONFIRM_TEST_ATTENDED',
  ConfirmTestBooked = 'CONFIRM_TEST_BOOKED',
  ConfirmTestResultsReturned = 'CONFIRM_TEST_RESULTS_RETURNED',
  Other = 'OTHER',
  ReviewTestResults = 'REVIEW_TEST_RESULTS',
  SendConsultationInvite = 'SEND_CONSULTATION_INVITE',
  SendTestInvite = 'SEND_TEST_INVITE',
}

export type TermsAndConditions = {
  __typename?: 'TermsAndConditions';
  dateTime: Scalars['DateTime'];
  decision: TermsAndConditionsStatus;
  version: Scalars['String'];
};

export enum TermsAndConditionsStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
}

export enum TestType {
  Blood = 'BLOOD',
  Ecg = 'ECG',
  Other = 'OTHER',
  Urine = 'URINE',
}

export type UpdateRegisterCareSettingClinicianInput = {
  careSettingAdministrator: Scalars['Boolean'];
  careSettingId: Scalars['ID'];
  defaultWardId: Scalars['ID'];
  firstName: Scalars['String'];
  mobileNumber: Scalars['String'];
  modules: Array<ModuleIdentifier>;
  surname: Scalars['String'];
  userId: Scalars['ID'];
  userState: InputMaybe<UserState>;
};

export type UpdateWardPatientTaskInput = {
  careSettingId: Scalars['ID'];
  completedDateTime: InputMaybe<Scalars['String']>;
  dueDateTime: Scalars['String'];
  isDeleted: InputMaybe<Scalars['Boolean']>;
  personId: Scalars['ID'];
  version: Scalars['Int'];
  wardPatientId: Scalars['ID'];
  wardPatientTaskId: Scalars['ID'];
  wardPatientTaskStatus: TaskListStatus;
  wardPatientTaskType: TaskListType;
  wardPatientTaskTypeDetail: InputMaybe<Scalars['String']>;
};

export type User = Identifier & {
  __typename?: 'User';
  attributes: UserAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserAttributes = {
  __typename?: 'UserAttributes';
  audit: Maybe<Audit>;
  currentWardPatientId: Maybe<Scalars['ID']>;
  defaultCareSettingId: Scalars['ID'];
  defaultWardId: Scalars['ID'];
  deletionRequested: Maybe<Scalars['DateTime']>;
  registrationStatus: Maybe<RegistrationStatus>;
  safId: Scalars['String'];
  termsAndConditions: Maybe<TermsAndConditions>;
  userState: Maybe<UserState>;
};

export type UserDocument = {
  __typename?: 'UserDocument';
  data: User;
};

export type UserInput = {
  defaultCareSettingId: Scalars['ID'];
  defaultWardId: Scalars['ID'];
};

export type UserModuleAvailability = Identifier & {
  __typename?: 'UserModuleAvailability';
  attributes: UserModuleAvailabilityAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserModuleAvailabilityAttributes = {
  __typename?: 'UserModuleAvailabilityAttributes';
  availabilityStatus: UserModuleAvailabilityStatus;
  careSettingModuleId: Scalars['ID'];
  moduleId: ModuleIdentifier;
  moduleName: Scalars['String'];
  moduleShortName: Scalars['String'];
  userId: Scalars['ID'];
};

export enum UserModuleAvailabilityStatus {
  Accessible = 'ACCESSIBLE',
  Visible = 'VISIBLE',
}

export type UserModuleRole = Identifier & {
  __typename?: 'UserModuleRole';
  attributes: UserModuleRoleAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type UserModuleRoleAttributes = {
  __typename?: 'UserModuleRoleAttributes';
  careSettingModuleId: Scalars['ID'];
  moduleRoleId: ModuleRole;
  userId: Scalars['ID'];
};

export type UserProfile = Identifier & {
  __typename?: 'UserProfile';
  attributes: UserAttributes;
  careSetting: Maybe<CareSetting>;
  careSettingClinician: Maybe<CareSettingClinician>;
  id: Scalars['ID'];
  type: Scalars['String'];
  userModuleAvailability: Array<Maybe<UserModuleAvailability>>;
  userModuleRoles: Array<Maybe<UserModuleRole>>;
};

export enum UserState {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

/** Updates a user state value */
export type UserStateInput = {
  userId: Scalars['ID'];
  userState: UserState;
};

export enum WaitingForDischarge {
  FinalAssessment = 'FINAL_ASSESSMENT',
  None = 'NONE',
  OccupationalTherapySignOff = 'OCCUPATIONAL_THERAPY_SIGN_OFF',
  PharmacyItems = 'PHARMACY_ITEMS',
  PhysiotherapySignOff = 'PHYSIOTHERAPY_SIGN_OFF',
  ResultsOfInvestigations = 'RESULTS_OF_INVESTIGATIONS',
  SocialCareInput = 'SOCIAL_CARE_INPUT',
}

export enum WaitingForPotentialDischarge {
  BloodTestResults = 'BLOOD_TEST_RESULTS',
  ClinicalSignOff = 'CLINICAL_SIGN_OFF',
  CtScan = 'CT_SCAN',
  FurtherInvestigations = 'FURTHER_INVESTIGATIONS',
  MedicalReviews = 'MEDICAL_REVIEWS',
  MriScan = 'MRI_SCAN',
  None = 'NONE',
  Other = 'OTHER',
  PatientTransport = 'PATIENT_TRANSPORT',
  ResultsOfInvestigations = 'RESULTS_OF_INVESTIGATIONS',
  SpecialityNurseReview = 'SPECIALITY_NURSE_REVIEW',
  SurgicalReview = 'SURGICAL_REVIEW',
  VirtualWardReferral = 'VIRTUAL_WARD_REFERRAL',
  XRays = 'X_RAYS',
}

export type Ward = Identifier & {
  __typename?: 'Ward';
  attributes: WardAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardAttributes = {
  __typename?: 'WardAttributes';
  audit: Maybe<Audit>;
  name: Scalars['String'];
  specialty: Maybe<SpecialtyType>;
  staffActualCount: Maybe<Scalars['Int']>;
  staffRequiredCount: Maybe<Scalars['Int']>;
  wardGroupId: Scalars['ID'];
  wardType: Maybe<WardType>;
};

export type WardDischarge = Identifier & {
  __typename?: 'WardDischarge';
  attributes: WardDischargeAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardDischargeAttributes = {
  __typename?: 'WardDischargeAttributes';
  audit: Maybe<Audit>;
  bedCleaningStatus: Maybe<BedCleaningStatus>;
  carePackageStatus: CarePackageStatusType;
  clinicallyReadyForDischargeDateTime: Maybe<Scalars['DateTime']>;
  comments: Maybe<Scalars['String']>;
  decisionMakerLastVisitedDateTime: Maybe<Scalars['DateTime']>;
  decisionMakerYN: YesNoType;
  dischargeActualDateTime: Maybe<Scalars['DateTime']>;
  dischargeAssessmentOutcome: Maybe<AwaitingFromDischargeAssess>;
  dischargeExpectedDateTime: Maybe<Scalars['DateTime']>;
  dischargeReason: Maybe<DischargeReasonType>;
  dischargeStatus: DischargeStatusType;
  /** @deprecated Use dischargeStatusFailedReasons */
  dischargeStatusFailedReason: Maybe<DischargeStatusFailed>;
  dischargeStatusFailedReasonOther: Maybe<Scalars['String']>;
  dischargeStatusFailedReasons: Maybe<Array<Maybe<DischargeStatusFailed>>>;
  dischargeToAssessDateTime: Maybe<Scalars['DateTime']>;
  isolationType: IsolationType;
  isolationTypeOther: Maybe<Scalars['String']>;
  pharmacy: Maybe<PharmacyStatusType>;
  porterStatus: Maybe<PorterStatusType>;
  readyForDischargeDateTime: Maybe<Scalars['DateTime']>;
  requireDischargeToAssess: Maybe<YesNoType>;
  requiresIsolationYN: Maybe<YesNoType>;
  socialCareYN: Maybe<YesNoType>;
  toTakeOutStatus: TtoStatusType;
  /** @deprecated Use waitingReasonForClinicallyReadyForDischarge */
  waitingReasonForClinicallyReadyForDischarge: Maybe<WaitingForDischarge>;
  /** @deprecated Use waitingReasonsForPotentialDischarge */
  waitingReasonForPotentialDischarge: Maybe<WaitingForPotentialDischarge>;
  waitingReasonOther: Maybe<Scalars['String']>;
  waitingReasonsForClinicallyReadyForDischarge: Maybe<
    Array<Maybe<WaitingForDischarge>>
  >;
  waitingReasonsForPotentialDischarge: Maybe<
    Array<Maybe<WaitingForPotentialDischarge>>
  >;
};

export type WardDischargeInput = {
  bedCleaningMethod: InputMaybe<BedCleaningMethod>;
  bedCleaningStatus: InputMaybe<BedCleaningStatus>;
  bedTransferConfirmed: InputMaybe<Scalars['Boolean']>;
  carePackageStatus: CarePackageStatusType;
  careSettingId: Scalars['ID'];
  clinicallyReadyForDischargeDateTime: InputMaybe<Scalars['DateTime']>;
  comments: InputMaybe<Scalars['String']>;
  decisionMakerLastVisitedDateTime: InputMaybe<Scalars['DateTime']>;
  decisionMakerYN: YesNoType;
  dischargeActualDateTime: InputMaybe<Scalars['DateTime']>;
  dischargeAssessmentOutcome: InputMaybe<AwaitingFromDischargeAssess>;
  dischargeExpectedDateTime: InputMaybe<Scalars['DateTime']>;
  dischargeReason: InputMaybe<DischargeReasonType>;
  dischargeStatus: DischargeStatusType;
  dischargeStatusFailedReason: InputMaybe<DischargeStatusFailed>;
  dischargeStatusFailedReasonOther: InputMaybe<Scalars['String']>;
  dischargeStatusFailedReasons: InputMaybe<
    Array<InputMaybe<DischargeStatusFailed>>
  >;
  dischargeToAssessDateTime: InputMaybe<Scalars['DateTime']>;
  isolationType: IsolationType;
  isolationTypeOther: InputMaybe<Scalars['String']>;
  pharmacy: InputMaybe<PharmacyStatusType>;
  porterStatus: InputMaybe<PorterStatusType>;
  readyForDischargeDateTime: InputMaybe<Scalars['DateTime']>;
  requireDischargeToAssess: InputMaybe<YesNoType>;
  requiresIsolationYN: InputMaybe<YesNoType>;
  socialCareYN: InputMaybe<YesNoType>;
  toTakeOutStatus: TtoStatusType;
  version: InputMaybe<Scalars['Int']>;
  waitingReasonForClinicallyReadyForDischarge: InputMaybe<WaitingForDischarge>;
  waitingReasonForPotentialDischarge: InputMaybe<WaitingForPotentialDischarge>;
  waitingReasonOther: InputMaybe<Scalars['String']>;
  waitingReasonsForClinicallyReadyForDischarge: InputMaybe<
    Array<InputMaybe<WaitingForDischarge>>
  >;
  waitingReasonsForPotentialDischarge: InputMaybe<
    Array<InputMaybe<WaitingForPotentialDischarge>>
  >;
  wardDischargeId: InputMaybe<Scalars['ID']>;
  wardGroupId: Scalars['ID'];
  wardId: Scalars['ID'];
  wardPatientId: Scalars['ID'];
};

export type WardPatient = Identifier & {
  __typename?: 'WardPatient';
  attributes: WardPatientAttributes;
  careSettingPatient: Maybe<CareSettingPatient>;
  id: Scalars['ID'];
  lastObservation: Maybe<WardPatientObservation>;
  type: Scalars['String'];
  ward: Maybe<Ward>;
  wardDischarge: Maybe<WardDischarge>;
};

export type WardPatientAttributes = {
  __typename?: 'WardPatientAttributes';
  admissionDateTime: Maybe<Scalars['DateTime']>;
  arrivalExpectedDateTime: Maybe<Scalars['DateTime']>;
  audit: Maybe<Audit>;
  careSettingId: Scalars['ID'];
  careSettingPatientId: Scalars['ID'];
  comments: Maybe<Scalars['String']>;
  covid19Status: Covid19Status;
  decisionToAdmitDateTime: Maybe<Scalars['DateTime']>;
  isolationType: IsolationType;
  isolationTypeOther: Maybe<Scalars['String']>;
  lastObservationDateTime: Maybe<Scalars['DateTime']>;
  /** User identifier of the clinician who last reviewed the patient. */
  lastReviewedBy: Maybe<Scalars['ID']>;
  lastReviewedDateTime: Maybe<Scalars['DateTime']>;
  monitoredConditions: Maybe<Array<MonitoredCondition>>;
  /** Observation Types assigned to this patient during admission. */
  observationTypes: Maybe<Array<ObservationType>>;
  origin: Maybe<OriginType>;
  outlierYN: Maybe<YesNoType>;
  porterStatus: Maybe<PorterStatusType>;
  predictedLengthOfStay: Maybe<Scalars['ISO8601Duration']>;
  primaryDiagnosis: PatientDiagnosis;
  requiresIsolationYN: Maybe<YesNoType>;
  /** @deprecated Period is deprecated so no longer required. Replaced with monitoring statuses */
  res_activeMonitoredConditions: Array<MonitoredCondition>;
  res_admittedBy: Maybe<Scalars['String']>;
  res_lastReviewedBy: Maybe<Scalars['String']>;
  secondaryDiagnosis: PatientDiagnosis;
  specialty: Maybe<SpecialtyType>;
  /** Observation Types submitted during this patient history, excluding derived observation types. */
  submittedObservationTypes: Maybe<Array<ObservationType>>;
  wardId: Scalars['ID'];
};

export type WardPatientComments = Identifier & {
  __typename?: 'WardPatientComments';
  attributes: WardPatientCommentsAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardPatientCommentsAttributes = {
  __typename?: 'WardPatientCommentsAttributes';
  audit: Maybe<Audit>;
  comment: Scalars['String'];
  commentType: CommentTypes;
  recordedDateTime: Scalars['DateTime'];
  wardPatientId: Scalars['ID'];
};

export type WardPatientCommentsInput = {
  comment: Scalars['String'];
  commentType: CommentTypes;
  recordedDateTime: InputMaybe<Scalars['DateTime']>;
  wardPatientId: Scalars['ID'];
};

/** Ward patient condition assessments - used by clinicians to assess patients based on certain factors. */
export type WardPatientConditionAssessment = Identifier & {
  __typename?: 'WardPatientConditionAssessment';
  attributes: WardPatientConditionAssessmentAttribute;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardPatientConditionAssessmentAttribute = {
  __typename?: 'WardPatientConditionAssessmentAttribute';
  assessmentComments: Maybe<Scalars['String']>;
  assessmentStartDateZoned: Scalars['String'];
  assessmentType: AssessmentType;
  audit: Maybe<Audit>;
  hypertensionAssessment: Maybe<HypertensionAssessment>;
  medicationAssessment: Maybe<MedicationAssessment>;
  monitoredCondition: PatientDiagnosis;
  res_createdBy: Maybe<Scalars['String']>;
  wardPatientId: Scalars['ID'];
};

export type WardPatientConditionAssessmentInput = {
  assessmentComments: InputMaybe<Scalars['String']>;
  assessmentStartDateZoned: Scalars['String'];
  assessmentType: AssessmentType;
  hypertensionAssessment: InputMaybe<HypertensionAssessmentInput>;
  medicationAssessment: InputMaybe<MedicationAssessmentInput>;
  monitoredCondition: PatientDiagnosis;
  wardPatientId: Scalars['ID'];
};

export type WardPatientInput = {
  admissionDateTime: InputMaybe<Scalars['DateTime']>;
  arrivalExpectedDateTime: InputMaybe<Scalars['DateTime']>;
  careSettingId: Scalars['ID'];
  careSettingPatientId: Scalars['ID'];
  comments: InputMaybe<Scalars['String']>;
  covid19Status: Covid19Status;
  isolationType: IsolationType;
  isolationTypeOther: InputMaybe<Scalars['String']>;
  monitoredConditions: InputMaybe<Array<InputMaybe<MonitoredConditionInput>>>;
  observationTypes: InputMaybe<Array<InputMaybe<ObservationType>>>;
  origin: OriginType;
  outlierYN: YesNoType;
  personId: Scalars['ID'];
  porterStatus: InputMaybe<PorterStatusType>;
  predictedLengthOfStay: InputMaybe<Scalars['ISO8601Duration']>;
  primaryDiagnosis: InputMaybe<PatientDiagnosis>;
  requiresIsolationYN: InputMaybe<YesNoType>;
  secondaryDiagnosis: InputMaybe<PatientDiagnosis>;
  specialty: SpecialtyType;
  userId: Scalars['ID'];
  wardBedId: Scalars['ID'];
  wardId: Scalars['ID'];
};

export type WardPatientObservation = Identifier & {
  __typename?: 'WardPatientObservation';
  attributes: WardPatientObservationAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardPatientObservationAttributes = {
  __typename?: 'WardPatientObservationAttributes';
  audit: Maybe<Audit>;
  /** Source of data */
  informationSource: Maybe<InformationSource>;
  observationType: ObservationType;
  recordedDateTime: Scalars['DateTime'];
  res_observationValue: Maybe<ObservationValue>;
  /** @deprecated Use res_observationValue */
  value: Maybe<Scalars['Float']>;
  wardPatientId: Scalars['ID'];
};

export type WardPatientSearchRecord = {
  __typename?: 'WardPatientSearchRecord';
  admissionStatus: Maybe<AdmissionStatus>;
  allConsultationStatuses: Maybe<
    Array<Maybe<MonitoredConditionConsultationStatusType>>
  >;
  allMonitoringStatuses: Maybe<Array<Maybe<MonitoringStatusType>>>;
  allTestStatuses: Maybe<Array<Maybe<MonitoredConditionTestStatusType>>>;
  careSettingId: Scalars['ID'];
  careSettingPatientId: Scalars['ID'];
  consultationStatusByCondition: Maybe<Scalars['JSON']>;
  dateOfBirth: Scalars['Date'];
  /** @deprecated Use monitoredConditions */
  dnMonitoredConditions: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastObservationDateTime: Maybe<Scalars['DateTime']>;
  lastReviewedDateTime: Maybe<Scalars['DateTime']>;
  monitoredConditions: Maybe<Array<Maybe<PatientDiagnosis>>>;
  monitoringStatusByCondition: Maybe<Scalars['JSON']>;
  nextTaskDueDateTime: Maybe<Scalars['String']>;
  nextTaskDueId: Maybe<Scalars['String']>;
  nextTaskDueType: Maybe<Scalars['String']>;
  nextTaskDueTypeDetail: Maybe<Scalars['String']>;
  nhsNumber: Maybe<Scalars['String']>;
  personId: Scalars['ID'];
  registeredGPSurgery: Maybe<Scalars['String']>;
  surname: Scalars['String'];
  testStatusByCondition: Maybe<Scalars['JSON']>;
};

export type WardPatientSearchRecordSearchResults = {
  __typename?: 'WardPatientSearchRecordSearchResults';
  hits: Scalars['Int'];
  searchRequestId: Scalars['ID'];
  searchResults: Array<WardPatientSearchRecord>;
  timedOut: Scalars['Boolean'];
};

export type WardPatientTask = Identifier & {
  __typename?: 'WardPatientTask';
  attributes: TaskListAttributes;
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type WardPatientUpdateConsultationStatusInput = {
  condition: PatientDiagnosis;
  consultationStatusType: MonitoredConditionConsultationStatusType;
  userId: Scalars['ID'];
  version: Scalars['Int'];
  wardPatientId: Scalars['ID'];
};

export type WardPatientUpdateMonitoringStatusInput = {
  condition: PatientDiagnosis;
  monitoringStatusType: MonitoringStatusType;
  userId: Scalars['ID'];
  version: Scalars['Int'];
  wardPatientId: Scalars['ID'];
};

export type WardPatientUpdateTestStatusInput = {
  condition: PatientDiagnosis;
  testStatusType: MonitoredConditionTestStatusType;
  userId: Scalars['ID'];
  version: Scalars['Int'];
  wardPatientId: Scalars['ID'];
};

export enum WardPatientsSortKey {
  LastReviewDatetime = 'LAST_REVIEW_DATETIME',
  MedicalCentre = 'MEDICAL_CENTRE',
  NextTaskDueTime = 'NEXT_TASK_DUE_TIME',
  ObservationDatetime = 'OBSERVATION_DATETIME',
  PatientName = 'PATIENT_NAME',
}

export enum WardType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL',
}

export enum YesNoType {
  No = 'NO',
  None = 'NONE',
  Yes = 'YES',
}

export type AddRegisterCareSettingClinicianMutationVariables = Exact<{
  input: AddRegisterCareSettingClinicianInput;
}>;

export type AddRegisterCareSettingClinicianMutation = {
  __typename?: 'Mutation';
  addRegisterCareSettingClinician: {
    __typename?: 'CareSettingClinicianRegistration';
    attributes: {
      __typename?: 'CareSettingClinicianRegistrationAttributes';
      moduleRoles: Array<ModuleRole>;
      careSettingClinician: {
        __typename?: 'CareSettingClinicianDocument';
        data: { __typename?: 'CareSettingClinician'; id: string };
      };
      user: {
        __typename?: 'UserDocument';
        data: { __typename?: 'User'; id: string };
      };
    };
  } | null;
};

export type AddWardPatientCommentMutationVariables = Exact<{
  input: WardPatientCommentsInput;
}>;

export type AddWardPatientCommentMutation = {
  __typename?: 'Mutation';
  wardPatientComments: {
    __typename?: 'WardPatientComments';
    attributes: {
      __typename?: 'WardPatientCommentsAttributes';
      comment: string;
      commentType: CommentTypes;
      recordedDateTime: string;
      wardPatientId: string;
    };
  };
};

export type AddWardPatientTaskMutationVariables = Exact<{
  input: InputMaybe<AddWardPatientTaskInput>;
}>;

export type AddWardPatientTaskMutation = {
  __typename?: 'Mutation';
  addWardPatientTask: {
    __typename?: 'WardPatientTask';
    type: string;
    id: string;
    attributes: {
      __typename?: 'TaskListAttributes';
      careSettingId: string;
      personId: string | null;
      wardPatientId: string | null;
      wardPatientTaskType: TaskListType | null;
      wardPatientTaskTypeDetail: string | null;
      wardPatientTaskStatus: TaskListStatus | null;
      dueDateTime: string | null;
      completedDateTime: string | null;
      taskContext: string | null;
      isDeleted: boolean | null;
      audit: { __typename?: 'Audit'; version: number } | null;
    };
  } | null;
};

export type AdmitCareSettingPatientMutationVariables = Exact<{
  input: CareSettingPatientAdmissionInput;
}>;

export type AdmitCareSettingPatientMutation = {
  __typename?: 'Mutation';
  admitCareSettingPatient: {
    __typename?: 'CareSettingPatientAdmission';
    id: string;
    attributes: {
      __typename?: 'CareSettingPatientAdmissionAttributes';
      careSettingPatient: {
        __typename?: 'CareSettingPatientDocument';
        data: {
          __typename?: 'CareSettingPatient';
          id: string;
          type: string;
          attributes: {
            __typename?: 'CareSettingPatientAttributes';
            careSettingId: string | null;
            personId: string;
          };
        };
      } | null;
    };
  } | null;
};

export type PatientStepupMutationVariables = Exact<{
  input: InputMaybe<PatientStepupInput>;
}>;

export type PatientStepupMutation = {
  __typename?: 'Mutation';
  patientStepup: {
    __typename?: 'PatientStepupAttributes';
    personId: string;
    careSettingId: string;
    nhsNumber: string;
    ethnicity: Ethnicity;
    observationTypes: Array<ObservationType>;
    medicalCentreReferenceId: string;
    monitoredConditions: Array<{
      __typename?: 'MonitoredCondition';
      condition: PatientDiagnosis;
      dischargeStatus: DischargeStatusType | null;
      reasonForDischarge: DischargeReasonType | null;
      comments: string | null;
      monitoringStatus: {
        __typename?: 'MonitoringStatus';
        status: MonitoringStatusType | null;
        statusUpdatedBy: string | null;
        statusUpdateDateTime: string | null;
        res_statusUpdatedBy: string | null;
      } | null;
      consultationStatus: {
        __typename?: 'MonitoredConditionConsultationStatus';
        status: MonitoredConditionConsultationStatusType | null;
        statusUpdatedBy: string | null;
        statusUpdateDateTime: string | null;
        res_statusUpdatedBy: string | null;
      } | null;
      testStatus: {
        __typename?: 'MonitoredConditionTestStatus';
        status: MonitoredConditionTestStatusType | null;
        statusUpdatedBy: string | null;
        statusUpdateDateTime: string | null;
        res_statusUpdatedBy: string | null;
      } | null;
    }>;
  } | null;
};

export type CareSettingStepUpInvitationMutationVariables = Exact<{
  input: CareSettingStepUpInvitationInput;
}>;

export type CareSettingStepUpInvitationMutation = {
  __typename?: 'Mutation';
  careSettingStepUpInvitation: {
    __typename?: 'CareSettingStepUpInvitationAttributes';
    personId: string | null;
    careSettingId: string | null;
    observationTypes: Array<ObservationType | null> | null;
    wardPatientId: string | null;
    nhsNumber: string | null;
    ethnicity: Ethnicity | null;
    medicalCentreReferenceId: string | null;
    gender: StepUpGender | null;
    address: string | null;
    postcode: string | null;
    phoneNumber: string | null;
    monitoredConditions: Array<{
      __typename?: 'MonitoredCondition';
      condition: PatientDiagnosis;
      dischargeStatus: DischargeStatusType | null;
      reasonForDischarge: DischargeReasonType | null;
      comments: string | null;
      monitoringStatus: {
        __typename?: 'MonitoringStatus';
        status: MonitoringStatusType | null;
        statusUpdatedBy: string | null;
        statusUpdateDateTime: string | null;
        res_statusUpdatedBy: string | null;
      } | null;
      consultationStatus: {
        __typename?: 'MonitoredConditionConsultationStatus';
        status: MonitoredConditionConsultationStatusType | null;
        statusUpdatedBy: string | null;
        statusUpdateDateTime: string | null;
        res_statusUpdatedBy: string | null;
      } | null;
      testStatus: {
        __typename?: 'MonitoredConditionTestStatus';
        status: MonitoredConditionTestStatusType | null;
        statusUpdatedBy: string | null;
        statusUpdateDateTime: string | null;
        res_statusUpdatedBy: string | null;
      } | null;
    } | null> | null;
  } | null;
};

export type CreateWardDischargeMutationVariables = Exact<{
  input: WardDischargeInput;
}>;

export type CreateWardDischargeMutation = {
  __typename?: 'Mutation';
  createWardDischarge: { __typename?: 'WardDischarge'; id: string };
};

export type CreateWardPatientMutationVariables = Exact<{
  input: WardPatientInput;
}>;

export type CreateWardPatientMutation = {
  __typename?: 'Mutation';
  createWardPatient: { __typename?: 'WardPatient'; id: string };
};

export type CreateWardPatientConditionAssessmentMutationVariables = Exact<{
  input: WardPatientConditionAssessmentInput;
}>;

export type CreateWardPatientConditionAssessmentMutation = {
  __typename?: 'Mutation';
  createWardPatientConditionAssessment: {
    __typename?: 'WardPatientConditionAssessment';
    id: string;
  };
};

export type UpdatePersonMutationVariables = Exact<{
  input: PersonInput;
  personId: Scalars['ID'];
}>;

export type UpdatePersonMutation = {
  __typename?: 'Mutation';
  updatePerson: {
    __typename?: 'Person';
    id: string;
    attributes: {
      __typename?: 'PersonAttributes';
      generalPracticeRegistration: {
        __typename?: 'PersonGeneralPracticeRegistration';
        accountId: string;
        odsCode: string;
        linkageKey: string;
        patientIdentifier: string;
      } | null;
      personalInformation: {
        __typename?: 'PersonPersonalInformation';
        firstName: string;
        surname: string;
        dateOfBirth: string | null;
        ethnicity: Ethnicity | null;
      };
      contactInformation: {
        __typename?: 'PersonContactInformation';
        primaryContactNumber: string | null;
        primaryEmailAddress: string | null;
      } | null;
      latestHealthInformation: {
        __typename?: 'HealthInformation';
        bloodPressureMedicationInformation: {
          __typename?: 'BloodPressureMedication';
          hadBPMedication: boolean;
          hasBPMedication: boolean;
          created: string;
        } | null;
        heightInformation: {
          __typename?: 'Height';
          created: string;
          unit: HeightUnit;
          value: number;
        } | null;
        smokingInformation: {
          __typename?: 'Smoking';
          created: string;
          status: SmokingStatus;
        } | null;
        ckdInformation: {
          __typename?: 'Ckd';
          ckdStatus: boolean;
          created: string;
        } | null;
        cvdInformation: {
          __typename?: 'Cvd';
          created: string;
          familyHadCvd: CvdFamilyInformation;
          hasCvd: boolean;
        } | null;
        diabetesInformation: {
          __typename?: 'Diabetes';
          created: string;
          diabetesStatus: DiabetesStatus;
        } | null;
      } | null;
    };
  } | null;
};

export type UpdateRegisterCareSettingClinicianMutationVariables = Exact<{
  input: UpdateRegisterCareSettingClinicianInput;
}>;

export type UpdateRegisterCareSettingClinicianMutation = {
  __typename?: 'Mutation';
  updateRegisterCareSettingClinician: {
    __typename?: 'CareSettingClinicianRegistration';
    attributes: {
      __typename?: 'CareSettingClinicianRegistrationAttributes';
      moduleRoles: Array<ModuleRole>;
      careSettingClinician: {
        __typename?: 'CareSettingClinicianDocument';
        data: { __typename?: 'CareSettingClinician'; id: string };
      };
      user: {
        __typename?: 'UserDocument';
        data: { __typename?: 'User'; id: string };
      };
    };
  } | null;
};

export type UpdateUserStateMutationVariables = Exact<{
  input: UserStateInput;
}>;

export type UpdateUserStateMutation = {
  __typename?: 'Mutation';
  updateUserState: { __typename?: 'User'; id: string } | null;
};

export type UpdateWardPatientAttributeCommentMutationVariables = Exact<{
  input: WardPatientCommentsInput;
}>;

export type UpdateWardPatientAttributeCommentMutation = {
  __typename?: 'Mutation';
  updateWardPatientAttributeComment: { __typename?: 'WardPatient'; id: string };
};

export type UpdateWardPatientConsultationStatusMutationVariables = Exact<{
  input: WardPatientUpdateConsultationStatusInput;
}>;

export type UpdateWardPatientConsultationStatusMutation = {
  __typename?: 'Mutation';
  updateWardPatientConsultationStatus: {
    __typename?: 'WardPatient';
    id: string;
  };
};

export type UpdateWardPatientMonitoringStatusMutationVariables = Exact<{
  input: WardPatientUpdateMonitoringStatusInput;
}>;

export type UpdateWardPatientMonitoringStatusMutation = {
  __typename?: 'Mutation';
  updateWardPatientMonitoringStatus: { __typename?: 'WardPatient'; id: string };
};

export type UpdateWardPatientTaskMutationVariables = Exact<{
  input: InputMaybe<UpdateWardPatientTaskInput>;
}>;

export type UpdateWardPatientTaskMutation = {
  __typename?: 'Mutation';
  updateWardPatientTask: {
    __typename?: 'WardPatientTask';
    id: string;
    type: string;
    attributes: {
      __typename?: 'TaskListAttributes';
      careSettingId: string;
      personId: string | null;
      wardPatientId: string | null;
      wardPatientTaskType: TaskListType | null;
      wardPatientTaskTypeDetail: string | null;
      wardPatientTaskStatus: TaskListStatus | null;
      dueDateTime: string | null;
      completedDateTime: string | null;
      taskContext: string | null;
      isDeleted: boolean | null;
      audit: { __typename?: 'Audit'; version: number } | null;
    };
  } | null;
};

export type UpdateWardPatientTestStatusMutationVariables = Exact<{
  input: WardPatientUpdateTestStatusInput;
}>;

export type UpdateWardPatientTestStatusMutation = {
  __typename?: 'Mutation';
  updateWardPatientTestStatus: { __typename?: 'WardPatient'; id: string };
};

export type GetCareSettingModulesQueryVariables = Exact<{
  careSettingId: Scalars['ID'];
}>;

export type GetCareSettingModulesQuery = {
  __typename?: 'Query';
  careSettingModules: Array<{
    __typename?: 'CareSettingModule';
    attributes: {
      __typename?: 'CareSettingModuleAttributes';
      moduleId: ModuleIdentifier;
    };
  }> | null;
};

export type GetHypertensionGuidelineApplicabilitySearchResultQueryVariables =
  Exact<{
    input: HypertensionGuidelineApplicabilitySearchInput;
  }>;

export type GetHypertensionGuidelineApplicabilitySearchResultQuery = {
  __typename?: 'Query';
  hypertensionGuidelineApplicabilitySearchResult: {
    __typename?: 'GuidelineApplicabilitySearchResult';
    attributes: {
      __typename?: 'GuidelineApplicabilitySearchResultAttributes';
      guidelineApplicabilityResult: {
        __typename?: 'GuidelineApplicabilityResult';
        applicableGuidelines: Array<{
          __typename?: 'ApplicableGuidelines';
          guideline: GuidelineType | null;
          guidelineState: boolean | null;
        } | null> | null;
      } | null;
    };
  };
};

export type GetLatestHealthInformationQueryVariables = Exact<{
  wardPatientId: Scalars['ID'];
  personId: Scalars['ID'];
}>;

export type GetLatestHealthInformationQuery = {
  __typename?: 'Query';
  clinicianPatientLatestInformation: {
    __typename?: 'ClinicianPatientLatestInformation';
    id: string;
    attributes: {
      __typename?: 'ClinicianPatientLatestInformationAttributes';
      res_bmiValue: number | null;
      personId: string;
      wardPatientId: string;
      latestHealthInformation: {
        __typename?: 'HealthInformation';
        heightInformation: {
          __typename?: 'Height';
          unit: HeightUnit;
          value: number;
          created: string;
        } | null;
        diabetesInformation: {
          __typename?: 'Diabetes';
          diabetesStatus: DiabetesStatus;
          created: string;
        } | null;
        cvdInformation: {
          __typename?: 'Cvd';
          hasCvd: boolean;
          created: string;
          familyHadCvd: CvdFamilyInformation;
        } | null;
        ckdInformation: {
          __typename?: 'Ckd';
          created: string;
          ckdStatus: boolean;
        } | null;
      } | null;
      latestObservationTypes: {
        __typename?: 'ClinicianPatientObservationTypeInformation';
        weightInformation: {
          __typename?: 'ClinicianPatientObservationTypeMeasurement';
          created: string;
          unit: string;
          value: number;
        } | null;
        fastingBloodGlucoseInformation: {
          __typename?: 'ClinicianPatientObservationTypeMeasurement';
          unit: string;
          value: number;
          created: string;
        } | null;
        hdlCholesterolInformation: {
          __typename?: 'ClinicianPatientObservationTypeMeasurement';
          created: string;
          unit: string;
          value: number;
        } | null;
        totalCholesterolInformation: {
          __typename?: 'ClinicianPatientObservationTypeMeasurement';
          created: string;
          unit: string;
          value: number;
        } | null;
      } | null;
    };
  } | null;
};

export type GetLatestLifestyleInformationQueryVariables = Exact<{
  wardPatientId: Scalars['ID'];
  personId: Scalars['ID'];
}>;

export type GetLatestLifestyleInformationQuery = {
  __typename?: 'Query';
  clinicianPatientLatestInformation: {
    __typename?: 'ClinicianPatientLatestInformation';
    id: string;
    attributes: {
      __typename?: 'ClinicianPatientLatestInformationAttributes';
      personId: string;
      wardPatientId: string;
      latestHealthInformation: {
        __typename?: 'HealthInformation';
        smokingInformation: {
          __typename?: 'Smoking';
          created: string;
          status: SmokingStatus;
        } | null;
      } | null;
    };
  } | null;
};

export type GetObservationVisualsQueryVariables = Exact<{
  input: ObservationVisualsSearchInput;
}>;

export type GetObservationVisualsQuery = {
  __typename?: 'Query';
  observationVisualsSearch: {
    __typename?: 'ObservationVisualsViewSearchResults';
    searchResults: Array<{
      __typename?: 'ObservationVisualsView';
      recordedDateTime: string;
      id: string;
      observationType: ObservationType;
      res_observationValues: {
        __typename?: 'ObservationVisualsValues';
        values: Array<{
          __typename?: 'ObservationVisualsValueItem';
          name: string | null;
          value: number | null;
        } | null> | null;
      } | null;
    }>;
  };
};

export type GetObservationAbsoluteAverageQueryVariables = Exact<{
  input: ObservationsAverageInput;
}>;

export type GetObservationAbsoluteAverageQuery = {
  __typename?: 'Query';
  observationsAbsoluteAverage: {
    __typename?: 'ObservationsAbsoluteAverage';
    attributes: {
      __typename?: 'ObservationsAbsoluteAverageAttributes';
      totalNoOfDays: number;
      totalNoOfReadings: number;
      observationsAbsoluteAverages: Array<{
        __typename?: 'ObservationsAbsoluteAverages';
        name: string;
        absoluteAverages: Array<{
          __typename?: 'AbsoluteAverages';
          res_averageValueInteger: number | null;
        }> | null;
      }> | null;
    };
  } | null;
};

export type GetObservationsForTodaysReadingsQueryVariables = Exact<{
  input: ObservationsForTodaysReadingsInput;
}>;

export type GetObservationsForTodaysReadingsQuery = {
  __typename?: 'Query';
  observationsForTodaysReadings: {
    __typename?: 'ObservationsForTodaysReadings';
    id: string;
    observationTypeReadings: Array<{
      __typename?: 'ObservationTypeReadings';
      id: string;
      observationType: ObservationType;
      res_dailyAverage: string | null;
      observationReadings: Array<{
        __typename?: 'ObservationsReadingsView';
        recordedDateTime: string;
        id: string;
        res_observationValues: {
          __typename?: 'ObservationVisualsValues';
          values: Array<{
            __typename?: 'ObservationVisualsValueItem';
            name: string | null;
            value: number | null;
          } | null> | null;
        } | null;
      } | null>;
    } | null>;
  } | null;
};

export type GetSearchPersonQueryVariables = Exact<{
  input: InputMaybe<SearchPersonInput>;
}>;

export type GetSearchPersonQuery = {
  __typename?: 'Query';
  searchPerson: {
    __typename?: 'SearchPersonAttributes';
    userId: string;
    isStandalonePatient: boolean;
    personId: string;
    careSettingId: string | null;
    personalInformation: {
      __typename?: 'PersonPersonalInformation';
      title: string;
      surname: string;
      firstName: string;
      dateOfBirth: string | null;
      nhsNumber: string | null;
      genderIdentifiedAs: GenderIdentifiedAs;
      genderAssignedAtBirth: GenderAssignedAtBirth | null;
      pronouns: Pronouns | null;
      mobility: Mobility | null;
      ethnicity: Ethnicity | null;
      primaryAddress: {
        __typename?: 'PrimaryAddress';
        line1: string | null;
        postalCode: string | null;
      } | null;
    };
    contactInformation: {
      __typename?: 'PersonContactInformation';
      primaryEmailAddress: string | null;
      primaryContactNumber: string | null;
    } | null;
  } | null;
};

export type GetRegisteredCareSettingCliniciansQueryVariables = Exact<{
  careSettingId: Scalars['ID'];
}>;

export type GetRegisteredCareSettingCliniciansQuery = {
  __typename?: 'Query';
  registeredCareSettingClinicians: Array<{
    __typename?: 'CareSettingClinician';
    id: string;
    attributes: {
      __typename?: 'CareSettingClinicianAttributes';
      email: string | null;
      firstName: string | null;
      surname: string | null;
      res_mobileNumber: string | null;
      userId: string;
      careSettingClinicianUser: {
        __typename?: 'User';
        attributes: {
          __typename?: 'UserAttributes';
          userState: UserState | null;
        };
      } | null;
      careSettingClinicianModuleAvailability: Array<{
        __typename?: 'UserModuleAvailability';
        attributes: {
          __typename?: 'UserModuleAvailabilityAttributes';
          moduleId: ModuleIdentifier;
          moduleShortName: string;
          availabilityStatus: UserModuleAvailabilityStatus;
        };
      } | null>;
      careSettingClinicianModuleRoles: Array<{
        __typename?: 'UserModuleRole';
        attributes: {
          __typename?: 'UserModuleRoleAttributes';
          moduleRoleId: ModuleRole;
        };
      } | null>;
    };
  }> | null;
};

export type TasksByStatusesQueryVariables = Exact<{
  wardPatientId: Scalars['ID'];
  statuses: InputMaybe<Array<TaskListStatus> | TaskListStatus>;
  limit: InputMaybe<Scalars['Int']>;
}>;

export type TasksByStatusesQuery = {
  __typename?: 'Query';
  tasksByStatuses: Array<{
    __typename?: 'WardPatientTask';
    id: string;
    type: string;
    attributes: {
      __typename?: 'TaskListAttributes';
      careSettingId: string;
      personId: string | null;
      wardPatientId: string | null;
      wardPatientTaskType: TaskListType | null;
      wardPatientTaskTypeDetail: string | null;
      wardPatientTaskStatus: TaskListStatus | null;
      dueDateTime: string | null;
      completedDateTime: string | null;
      taskContext: string | null;
      isDeleted: boolean | null;
      audit: {
        __typename?: 'Audit';
        version: number;
        lastUpdated: string | null;
        created: string | null;
        updatedByUser: {
          __typename?: 'UserProfile';
          careSettingClinician: {
            __typename?: 'CareSettingClinician';
            attributes: {
              __typename?: 'CareSettingClinicianAttributes';
              displayName: string | null;
              firstName: string | null;
              surname: string | null;
            };
          } | null;
        } | null;
      } | null;
    };
  } | null> | null;
};

export type GetUserProfileBySafIdQueryVariables = Exact<{
  safId: Scalars['String'];
}>;

export type GetUserProfileBySafIdQuery = {
  __typename?: 'Query';
  userProfile: {
    __typename?: 'UserProfile';
    id: string;
    attributes: {
      __typename?: 'UserAttributes';
      defaultCareSettingId: string;
      defaultWardId: string;
      userState: UserState | null;
      safId: string;
      audit: {
        __typename?: 'Audit';
        created: string | null;
        createdBy: string | null;
        lastUpdated: string | null;
        updatedBy: string | null;
        version: number;
      } | null;
    };
    careSetting: {
      __typename?: 'CareSetting';
      attributes: {
        __typename?: 'CareSettingAttributes';
        name: string;
        shortName: string;
        relatedOrganisations: Array<{
          __typename?: 'RelatedOrganisation';
          externalReferenceId: string | null;
          name: string;
          type: RelatedOrganisationType;
        } | null> | null;
      };
    } | null;
    userModuleAvailability: Array<{
      __typename?: 'UserModuleAvailability';
      type: string;
      id: string;
      attributes: {
        __typename?: 'UserModuleAvailabilityAttributes';
        availabilityStatus: UserModuleAvailabilityStatus;
        careSettingModuleId: string;
        moduleId: ModuleIdentifier;
        moduleName: string;
        moduleShortName: string;
        userId: string;
      };
    } | null>;
    careSettingClinician: {
      __typename?: 'CareSettingClinician';
      attributes: {
        __typename?: 'CareSettingClinicianAttributes';
        firstName: string | null;
        displayName: string | null;
      };
    } | null;
    userModuleRoles: Array<{
      __typename?: 'UserModuleRole';
      attributes: {
        __typename?: 'UserModuleRoleAttributes';
        moduleRoleId: ModuleRole;
      };
    } | null>;
  } | null;
};

export type GetWardPatientQueryVariables = Exact<{
  wardPatientId: Scalars['ID'];
  careSettingId: InputMaybe<Scalars['ID']>;
}>;

export type GetWardPatientQuery = {
  __typename?: 'Query';
  wardPatient: {
    __typename?: 'WardPatient';
    id: string;
    attributes: {
      __typename?: 'WardPatientAttributes';
      admissionDateTime: string | null;
      comments: string | null;
      covid19Status: Covid19Status;
      observationTypes: Array<ObservationType> | null;
      submittedObservationTypes: Array<ObservationType> | null;
      careSettingId: string;
      lastObservationDateTime: string | null;
      lastReviewedDateTime: string | null;
      res_lastReviewedBy: string | null;
      res_admittedBy: string | null;
      monitoredConditions: Array<{
        __typename?: 'MonitoredCondition';
        condition: PatientDiagnosis;
        monitoringStatus: {
          __typename?: 'MonitoringStatus';
          status: MonitoringStatusType | null;
          statusUpdateDateTime: string | null;
          statusUpdatedBy: string | null;
          res_statusUpdatedBy: string | null;
        } | null;
        consultationStatus: {
          __typename?: 'MonitoredConditionConsultationStatus';
          status: MonitoredConditionConsultationStatusType | null;
          statusUpdateDateTime: string | null;
          statusUpdatedBy: string | null;
          res_statusUpdatedBy: string | null;
        } | null;
        testStatus: {
          __typename?: 'MonitoredConditionTestStatus';
          status: MonitoredConditionTestStatusType | null;
          statusUpdateDateTime: string | null;
          statusUpdatedBy: string | null;
          res_statusUpdatedBy: string | null;
        } | null;
      }> | null;
      audit: { __typename?: 'Audit'; version: number } | null;
    };
    wardDischarge: {
      __typename?: 'WardDischarge';
      id: string;
      attributes: {
        __typename?: 'WardDischargeAttributes';
        comments: string | null;
        dischargeReason: DischargeReasonType | null;
        dischargeActualDateTime: string | null;
      };
    } | null;
    careSettingPatient: {
      __typename?: 'CareSettingPatient';
      id: string;
      attributes: {
        __typename?: 'CareSettingPatientAttributes';
        admissionStatus: AdmissionStatus | null;
        admissionStatusUpdateDateTime: string | null;
      };
      person: {
        __typename?: 'Person';
        id: string;
        attributes: {
          __typename?: 'PersonAttributes';
          registeredGPSurgery: string | null;
          contactInformation: {
            __typename?: 'PersonContactInformation';
            primaryEmailAddress: string | null;
            primaryContactNumber: string | null;
          } | null;
          contactParty: Array<{
            __typename?: 'PersonContactPartyFields';
            primaryContactNumber: string | null;
            personRelationshipType: PersonRelationshipType | null;
            humanNames: Array<{
              __typename?: 'AIMHumanName';
              given: string | null;
              family: string | null;
            } | null> | null;
          } | null> | null;
          personalInformation: {
            __typename?: 'PersonPersonalInformation';
            title: string;
            surname: string;
            pronouns: Pronouns | null;
            nhsNumber: string | null;
            mobility: Mobility | null;
            genderIdentifiedAs: GenderIdentifiedAs;
            genderAssignedAtBirth: GenderAssignedAtBirth | null;
            firstName: string;
            ethnicity: Ethnicity | null;
            dateOfBirth: string | null;
            primaryAddress: {
              __typename?: 'PrimaryAddress';
              postalCode: string | null;
              line1: string | null;
            } | null;
          };
        };
      };
    } | null;
    ward: {
      __typename?: 'Ward';
      id: string;
      attributes: { __typename?: 'WardAttributes'; wardGroupId: string };
    } | null;
  } | null;
};

export type GetWardPatientCommentsQueryVariables = Exact<{
  wardPatientId: Scalars['ID'];
}>;

export type GetWardPatientCommentsQuery = {
  __typename?: 'Query';
  wardPatientComments: Array<{
    __typename?: 'WardPatientComments';
    id: string;
    type: string;
    attributes: {
      __typename?: 'WardPatientCommentsAttributes';
      comment: string;
      commentType: CommentTypes;
      recordedDateTime: string;
      wardPatientId: string;
      audit: {
        __typename?: 'Audit';
        created: string | null;
        createdBy: string | null;
        createdByUser: {
          __typename?: 'UserProfile';
          careSettingClinician: {
            __typename?: 'CareSettingClinician';
            attributes: {
              __typename?: 'CareSettingClinicianAttributes';
              displayName: string | null;
            };
          } | null;
        } | null;
      } | null;
    };
  }>;
};

export type GetWardPatientConditionAssessmentsQueryVariables = Exact<{
  wardPatientId: Scalars['ID'];
  careSettingId: Scalars['ID'];
}>;

export type GetWardPatientConditionAssessmentsQuery = {
  __typename?: 'Query';
  wardPatientConditionAssessment: Array<{
    __typename?: 'WardPatientConditionAssessment';
    id: string;
    attributes: {
      __typename?: 'WardPatientConditionAssessmentAttribute';
      assessmentComments: string | null;
      assessmentStartDateZoned: string;
      assessmentType: AssessmentType;
      monitoredCondition: PatientDiagnosis;
      res_createdBy: string | null;
      hypertensionAssessment: {
        __typename?: 'HypertensionAssessment';
        averageDiastolic: number;
        averageSystolic: number;
        conditionTestTypeOther: string | null;
        conditionTestTypes: Array<TestType | null> | null;
        consultationRequiredYN: YesNoType | null;
        consultationTimeframe: ConsultationTimeframe | null;
        customConsultationTimeframe: string | null;
        fitToSelfMonitorYN: YesNoType | null;
        qriskScore: number | null;
        reasonForNoUrgentClinicalIntervention: string | null;
        totalDays: number;
        testsRequiredYN: YesNoType | null;
        totalReadings: number;
        urgentClinicalInterventionRequiredYN: YesNoType | null;
        urgentClinicalInterventionTakenPlaceYN: YesNoType | null;
      } | null;
      medicationAssessment: {
        __typename?: 'MedicationAssessment';
        hasEverTakenBloodPressureMedicationYN: YesNoType | null;
        isTakingBloodPressureMedicationYN: YesNoType | null;
        medicationProtocol: MedicationProtocol | null;
        newMedicationProtocol: MedicationProtocol | null;
        comments: string | null;
        medicationOutcome: MedicationOutcome | null;
        stoppedMedicationOutcomeComments: string | null;
        guidelineRecommendationDecisions: Array<{
          __typename?: 'GuidelineRecommendationDecisions';
          guidelineRecommended: GuidelineType;
          guidelineAccepted: YesNoType;
          recommendationDeclinedReason: GuidelineDeclinedReason | null;
          otherRecommendationDeclinedReasonComments: string | null;
        } | null> | null;
      } | null;
      audit: { __typename?: 'Audit'; created: string | null } | null;
    };
  }>;
};

export type GetWardPatientStatusesQueryVariables = Exact<{
  wardPatientId: Scalars['ID'];
}>;

export type GetWardPatientStatusesQuery = {
  __typename?: 'Query';
  wardPatient: {
    __typename?: 'WardPatient';
    id: string;
    attributes: {
      __typename?: 'WardPatientAttributes';
      monitoredConditions: Array<{
        __typename?: 'MonitoredCondition';
        condition: PatientDiagnosis;
        monitoringStatus: {
          __typename?: 'MonitoringStatus';
          status: MonitoringStatusType | null;
        } | null;
        consultationStatus: {
          __typename?: 'MonitoredConditionConsultationStatus';
          status: MonitoredConditionConsultationStatusType | null;
        } | null;
        testStatus: {
          __typename?: 'MonitoredConditionTestStatus';
          status: MonitoredConditionTestStatusType | null;
        } | null;
      }> | null;
    };
  } | null;
};

export type GetWardPatientsQueryVariables = Exact<{
  wardId: Scalars['ID'];
  sortKey: InputMaybe<WardPatientsSortKey>;
  ascending: InputMaybe<Scalars['Boolean']>;
  from: InputMaybe<Scalars['Int']>;
  limit: InputMaybe<Scalars['Int']>;
  search: InputMaybe<Scalars['String']>;
  monitoredConditions: InputMaybe<Array<PatientDiagnosis> | PatientDiagnosis>;
  registeredGPSurgeries: InputMaybe<
    Array<Scalars['String']> | Scalars['String']
  >;
  admissionStatuses: InputMaybe<Array<AdmissionStatus> | AdmissionStatus>;
  monitoringStatuses: InputMaybe<
    Array<MonitoringStatusType> | MonitoringStatusType
  >;
  testStatuses: InputMaybe<
    Array<MonitoredConditionTestStatusType> | MonitoredConditionTestStatusType
  >;
  consultationStatuses: InputMaybe<
    | Array<MonitoredConditionConsultationStatusType>
    | MonitoredConditionConsultationStatusType
  >;
  taskDueTypes: InputMaybe<Array<TaskListType> | TaskListType>;
}>;

export type GetWardPatientsQuery = {
  __typename?: 'Query';
  wardPatientsSearch: {
    __typename?: 'WardPatientSearchRecordSearchResults';
    hits: number;
    searchResults: Array<{
      __typename?: 'WardPatientSearchRecord';
      id: string;
      careSettingPatientId: string;
      careSettingId: string;
      personId: string;
      firstName: string;
      surname: string;
      nhsNumber: string | null;
      dateOfBirth: string;
      lastReviewedDateTime: string | null;
      lastObservationDateTime: string | null;
      dnMonitoredConditions: string | null;
      monitoredConditions: Array<PatientDiagnosis | null> | null;
      registeredGPSurgery: string | null;
      admissionStatus: AdmissionStatus | null;
      monitoringStatusByCondition: any | null;
      consultationStatusByCondition: any | null;
      testStatusByCondition: any | null;
      nextTaskDueId: string | null;
      nextTaskDueDateTime: string | null;
      nextTaskDueType: string | null;
      nextTaskDueTypeDetail: string | null;
    }>;
  };
};

export const AddRegisterCareSettingClinicianDocument = gql`
  mutation AddRegisterCareSettingClinician(
    $input: AddRegisterCareSettingClinicianInput!
  ) {
    addRegisterCareSettingClinician(input: $input) {
      attributes {
        careSettingClinician {
          data {
            id
          }
        }
        moduleRoles
        user {
          data {
            id
          }
        }
      }
    }
  }
`;
export type AddRegisterCareSettingClinicianMutationFn = Apollo.MutationFunction<
  AddRegisterCareSettingClinicianMutation,
  AddRegisterCareSettingClinicianMutationVariables
>;

/**
 * __useAddRegisterCareSettingClinicianMutation__
 *
 * To run a mutation, you first call `useAddRegisterCareSettingClinicianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegisterCareSettingClinicianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegisterCareSettingClinicianMutation, { data, loading, error }] = useAddRegisterCareSettingClinicianMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRegisterCareSettingClinicianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRegisterCareSettingClinicianMutation,
    AddRegisterCareSettingClinicianMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRegisterCareSettingClinicianMutation,
    AddRegisterCareSettingClinicianMutationVariables
  >(AddRegisterCareSettingClinicianDocument, options);
}
export type AddRegisterCareSettingClinicianMutationHookResult = ReturnType<
  typeof useAddRegisterCareSettingClinicianMutation
>;
export type AddRegisterCareSettingClinicianMutationResult =
  Apollo.MutationResult<AddRegisterCareSettingClinicianMutation>;
export type AddRegisterCareSettingClinicianMutationOptions =
  Apollo.BaseMutationOptions<
    AddRegisterCareSettingClinicianMutation,
    AddRegisterCareSettingClinicianMutationVariables
  >;
export const AddWardPatientCommentDocument = gql`
  mutation AddWardPatientComment($input: WardPatientCommentsInput!) {
    wardPatientComments(input: $input) {
      attributes {
        comment
        commentType
        recordedDateTime
        wardPatientId
      }
    }
  }
`;
export type AddWardPatientCommentMutationFn = Apollo.MutationFunction<
  AddWardPatientCommentMutation,
  AddWardPatientCommentMutationVariables
>;

/**
 * __useAddWardPatientCommentMutation__
 *
 * To run a mutation, you first call `useAddWardPatientCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWardPatientCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWardPatientCommentMutation, { data, loading, error }] = useAddWardPatientCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWardPatientCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddWardPatientCommentMutation,
    AddWardPatientCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddWardPatientCommentMutation,
    AddWardPatientCommentMutationVariables
  >(AddWardPatientCommentDocument, options);
}
export type AddWardPatientCommentMutationHookResult = ReturnType<
  typeof useAddWardPatientCommentMutation
>;
export type AddWardPatientCommentMutationResult =
  Apollo.MutationResult<AddWardPatientCommentMutation>;
export type AddWardPatientCommentMutationOptions = Apollo.BaseMutationOptions<
  AddWardPatientCommentMutation,
  AddWardPatientCommentMutationVariables
>;
export const AddWardPatientTaskDocument = gql`
  mutation AddWardPatientTask($input: AddWardPatientTaskInput) {
    addWardPatientTask(input: $input) {
      type
      id
      attributes {
        audit {
          version
        }
        careSettingId
        personId
        wardPatientId
        wardPatientTaskType
        wardPatientTaskTypeDetail
        wardPatientTaskStatus
        dueDateTime
        completedDateTime
        taskContext
        isDeleted
      }
    }
  }
`;
export type AddWardPatientTaskMutationFn = Apollo.MutationFunction<
  AddWardPatientTaskMutation,
  AddWardPatientTaskMutationVariables
>;

/**
 * __useAddWardPatientTaskMutation__
 *
 * To run a mutation, you first call `useAddWardPatientTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWardPatientTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWardPatientTaskMutation, { data, loading, error }] = useAddWardPatientTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWardPatientTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddWardPatientTaskMutation,
    AddWardPatientTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddWardPatientTaskMutation,
    AddWardPatientTaskMutationVariables
  >(AddWardPatientTaskDocument, options);
}
export type AddWardPatientTaskMutationHookResult = ReturnType<
  typeof useAddWardPatientTaskMutation
>;
export type AddWardPatientTaskMutationResult =
  Apollo.MutationResult<AddWardPatientTaskMutation>;
export type AddWardPatientTaskMutationOptions = Apollo.BaseMutationOptions<
  AddWardPatientTaskMutation,
  AddWardPatientTaskMutationVariables
>;
export const AdmitCareSettingPatientDocument = gql`
  mutation AdmitCareSettingPatient($input: CareSettingPatientAdmissionInput!) {
    admitCareSettingPatient(input: $input) {
      id
      attributes {
        careSettingPatient {
          data {
            id
            type
            attributes {
              careSettingId
              personId
            }
          }
        }
      }
    }
  }
`;
export type AdmitCareSettingPatientMutationFn = Apollo.MutationFunction<
  AdmitCareSettingPatientMutation,
  AdmitCareSettingPatientMutationVariables
>;

/**
 * __useAdmitCareSettingPatientMutation__
 *
 * To run a mutation, you first call `useAdmitCareSettingPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdmitCareSettingPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [admitCareSettingPatientMutation, { data, loading, error }] = useAdmitCareSettingPatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdmitCareSettingPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AdmitCareSettingPatientMutation,
    AdmitCareSettingPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AdmitCareSettingPatientMutation,
    AdmitCareSettingPatientMutationVariables
  >(AdmitCareSettingPatientDocument, options);
}
export type AdmitCareSettingPatientMutationHookResult = ReturnType<
  typeof useAdmitCareSettingPatientMutation
>;
export type AdmitCareSettingPatientMutationResult =
  Apollo.MutationResult<AdmitCareSettingPatientMutation>;
export type AdmitCareSettingPatientMutationOptions = Apollo.BaseMutationOptions<
  AdmitCareSettingPatientMutation,
  AdmitCareSettingPatientMutationVariables
>;
export const PatientStepupDocument = gql`
  mutation PatientStepup($input: PatientStepupInput) {
    patientStepup(input: $input) {
      personId
      careSettingId
      nhsNumber
      ethnicity
      observationTypes
      monitoredConditions {
        condition
        dischargeStatus
        reasonForDischarge
        comments
        monitoringStatus {
          status
          statusUpdatedBy
          statusUpdateDateTime
          res_statusUpdatedBy
        }
        consultationStatus {
          status
          statusUpdatedBy
          statusUpdateDateTime
          res_statusUpdatedBy
        }
        testStatus {
          status
          statusUpdatedBy
          statusUpdateDateTime
          res_statusUpdatedBy
        }
      }
      medicalCentreReferenceId
    }
  }
`;
export type PatientStepupMutationFn = Apollo.MutationFunction<
  PatientStepupMutation,
  PatientStepupMutationVariables
>;

/**
 * __usePatientStepupMutation__
 *
 * To run a mutation, you first call `usePatientStepupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatientStepupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patientStepupMutation, { data, loading, error }] = usePatientStepupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePatientStepupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatientStepupMutation,
    PatientStepupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PatientStepupMutation,
    PatientStepupMutationVariables
  >(PatientStepupDocument, options);
}
export type PatientStepupMutationHookResult = ReturnType<
  typeof usePatientStepupMutation
>;
export type PatientStepupMutationResult =
  Apollo.MutationResult<PatientStepupMutation>;
export type PatientStepupMutationOptions = Apollo.BaseMutationOptions<
  PatientStepupMutation,
  PatientStepupMutationVariables
>;
export const CareSettingStepUpInvitationDocument = gql`
  mutation CareSettingStepUpInvitation(
    $input: CareSettingStepUpInvitationInput!
  ) {
    careSettingStepUpInvitation(input: $input) {
      personId
      careSettingId
      observationTypes
      monitoredConditions {
        condition
        dischargeStatus
        reasonForDischarge
        comments
        monitoringStatus {
          status
          statusUpdatedBy
          statusUpdateDateTime
          res_statusUpdatedBy
        }
        consultationStatus {
          status
          statusUpdatedBy
          statusUpdateDateTime
          res_statusUpdatedBy
        }
        testStatus {
          status
          statusUpdatedBy
          statusUpdateDateTime
          res_statusUpdatedBy
        }
      }
      wardPatientId
      nhsNumber
      ethnicity
      medicalCentreReferenceId
      gender
      address
      postcode
      phoneNumber
    }
  }
`;
export type CareSettingStepUpInvitationMutationFn = Apollo.MutationFunction<
  CareSettingStepUpInvitationMutation,
  CareSettingStepUpInvitationMutationVariables
>;

/**
 * __useCareSettingStepUpInvitationMutation__
 *
 * To run a mutation, you first call `useCareSettingStepUpInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCareSettingStepUpInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [careSettingStepUpInvitationMutation, { data, loading, error }] = useCareSettingStepUpInvitationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCareSettingStepUpInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CareSettingStepUpInvitationMutation,
    CareSettingStepUpInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CareSettingStepUpInvitationMutation,
    CareSettingStepUpInvitationMutationVariables
  >(CareSettingStepUpInvitationDocument, options);
}
export type CareSettingStepUpInvitationMutationHookResult = ReturnType<
  typeof useCareSettingStepUpInvitationMutation
>;
export type CareSettingStepUpInvitationMutationResult =
  Apollo.MutationResult<CareSettingStepUpInvitationMutation>;
export type CareSettingStepUpInvitationMutationOptions =
  Apollo.BaseMutationOptions<
    CareSettingStepUpInvitationMutation,
    CareSettingStepUpInvitationMutationVariables
  >;
export const CreateWardDischargeDocument = gql`
  mutation CreateWardDischarge($input: WardDischargeInput!) {
    createWardDischarge(input: $input) {
      id
    }
  }
`;
export type CreateWardDischargeMutationFn = Apollo.MutationFunction<
  CreateWardDischargeMutation,
  CreateWardDischargeMutationVariables
>;

/**
 * __useCreateWardDischargeMutation__
 *
 * To run a mutation, you first call `useCreateWardDischargeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWardDischargeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWardDischargeMutation, { data, loading, error }] = useCreateWardDischargeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWardDischargeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWardDischargeMutation,
    CreateWardDischargeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWardDischargeMutation,
    CreateWardDischargeMutationVariables
  >(CreateWardDischargeDocument, options);
}
export type CreateWardDischargeMutationHookResult = ReturnType<
  typeof useCreateWardDischargeMutation
>;
export type CreateWardDischargeMutationResult =
  Apollo.MutationResult<CreateWardDischargeMutation>;
export type CreateWardDischargeMutationOptions = Apollo.BaseMutationOptions<
  CreateWardDischargeMutation,
  CreateWardDischargeMutationVariables
>;
export const CreateWardPatientDocument = gql`
  mutation CreateWardPatient($input: WardPatientInput!) {
    createWardPatient(input: $input) {
      id
    }
  }
`;
export type CreateWardPatientMutationFn = Apollo.MutationFunction<
  CreateWardPatientMutation,
  CreateWardPatientMutationVariables
>;

/**
 * __useCreateWardPatientMutation__
 *
 * To run a mutation, you first call `useCreateWardPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWardPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWardPatientMutation, { data, loading, error }] = useCreateWardPatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWardPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWardPatientMutation,
    CreateWardPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWardPatientMutation,
    CreateWardPatientMutationVariables
  >(CreateWardPatientDocument, options);
}
export type CreateWardPatientMutationHookResult = ReturnType<
  typeof useCreateWardPatientMutation
>;
export type CreateWardPatientMutationResult =
  Apollo.MutationResult<CreateWardPatientMutation>;
export type CreateWardPatientMutationOptions = Apollo.BaseMutationOptions<
  CreateWardPatientMutation,
  CreateWardPatientMutationVariables
>;
export const CreateWardPatientConditionAssessmentDocument = gql`
  mutation CreateWardPatientConditionAssessment(
    $input: WardPatientConditionAssessmentInput!
  ) {
    createWardPatientConditionAssessment(input: $input) {
      id
    }
  }
`;
export type CreateWardPatientConditionAssessmentMutationFn =
  Apollo.MutationFunction<
    CreateWardPatientConditionAssessmentMutation,
    CreateWardPatientConditionAssessmentMutationVariables
  >;

/**
 * __useCreateWardPatientConditionAssessmentMutation__
 *
 * To run a mutation, you first call `useCreateWardPatientConditionAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWardPatientConditionAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWardPatientConditionAssessmentMutation, { data, loading, error }] = useCreateWardPatientConditionAssessmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWardPatientConditionAssessmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWardPatientConditionAssessmentMutation,
    CreateWardPatientConditionAssessmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWardPatientConditionAssessmentMutation,
    CreateWardPatientConditionAssessmentMutationVariables
  >(CreateWardPatientConditionAssessmentDocument, options);
}
export type CreateWardPatientConditionAssessmentMutationHookResult = ReturnType<
  typeof useCreateWardPatientConditionAssessmentMutation
>;
export type CreateWardPatientConditionAssessmentMutationResult =
  Apollo.MutationResult<CreateWardPatientConditionAssessmentMutation>;
export type CreateWardPatientConditionAssessmentMutationOptions =
  Apollo.BaseMutationOptions<
    CreateWardPatientConditionAssessmentMutation,
    CreateWardPatientConditionAssessmentMutationVariables
  >;
export const UpdatePersonDocument = gql`
  mutation updatePerson($input: PersonInput!, $personId: ID!) {
    updatePerson(input: $input, personId: $personId) {
      id
      attributes {
        generalPracticeRegistration {
          accountId
          odsCode
          linkageKey
          patientIdentifier
        }
        personalInformation {
          firstName
          surname
          dateOfBirth
          ethnicity
        }
        contactInformation {
          primaryContactNumber
          primaryEmailAddress
        }
        latestHealthInformation {
          bloodPressureMedicationInformation {
            hadBPMedication
            hasBPMedication
            created
          }
          heightInformation {
            created
            unit
            value
          }
          smokingInformation {
            created
            status
          }
          ckdInformation {
            ckdStatus
            created
          }
          cvdInformation {
            created
            familyHadCvd
            hasCvd
          }
          diabetesInformation {
            created
            diabetesStatus
          }
        }
      }
    }
  }
`;
export type UpdatePersonMutationFn = Apollo.MutationFunction<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      input: // value for 'input'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useUpdatePersonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePersonMutation,
    UpdatePersonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePersonMutation,
    UpdatePersonMutationVariables
  >(UpdatePersonDocument, options);
}
export type UpdatePersonMutationHookResult = ReturnType<
  typeof useUpdatePersonMutation
>;
export type UpdatePersonMutationResult =
  Apollo.MutationResult<UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<
  UpdatePersonMutation,
  UpdatePersonMutationVariables
>;
export const UpdateRegisterCareSettingClinicianDocument = gql`
  mutation UpdateRegisterCareSettingClinician(
    $input: UpdateRegisterCareSettingClinicianInput!
  ) {
    updateRegisterCareSettingClinician(input: $input) {
      attributes {
        careSettingClinician {
          data {
            id
          }
        }
        moduleRoles
        user {
          data {
            id
          }
        }
      }
    }
  }
`;
export type UpdateRegisterCareSettingClinicianMutationFn =
  Apollo.MutationFunction<
    UpdateRegisterCareSettingClinicianMutation,
    UpdateRegisterCareSettingClinicianMutationVariables
  >;

/**
 * __useUpdateRegisterCareSettingClinicianMutation__
 *
 * To run a mutation, you first call `useUpdateRegisterCareSettingClinicianMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRegisterCareSettingClinicianMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRegisterCareSettingClinicianMutation, { data, loading, error }] = useUpdateRegisterCareSettingClinicianMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRegisterCareSettingClinicianMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRegisterCareSettingClinicianMutation,
    UpdateRegisterCareSettingClinicianMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateRegisterCareSettingClinicianMutation,
    UpdateRegisterCareSettingClinicianMutationVariables
  >(UpdateRegisterCareSettingClinicianDocument, options);
}
export type UpdateRegisterCareSettingClinicianMutationHookResult = ReturnType<
  typeof useUpdateRegisterCareSettingClinicianMutation
>;
export type UpdateRegisterCareSettingClinicianMutationResult =
  Apollo.MutationResult<UpdateRegisterCareSettingClinicianMutation>;
export type UpdateRegisterCareSettingClinicianMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateRegisterCareSettingClinicianMutation,
    UpdateRegisterCareSettingClinicianMutationVariables
  >;
export const UpdateUserStateDocument = gql`
  mutation UpdateUserState($input: UserStateInput!) {
    updateUserState(input: $input) {
      id
    }
  }
`;
export type UpdateUserStateMutationFn = Apollo.MutationFunction<
  UpdateUserStateMutation,
  UpdateUserStateMutationVariables
>;

/**
 * __useUpdateUserStateMutation__
 *
 * To run a mutation, you first call `useUpdateUserStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStateMutation, { data, loading, error }] = useUpdateUserStateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserStateMutation,
    UpdateUserStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserStateMutation,
    UpdateUserStateMutationVariables
  >(UpdateUserStateDocument, options);
}
export type UpdateUserStateMutationHookResult = ReturnType<
  typeof useUpdateUserStateMutation
>;
export type UpdateUserStateMutationResult =
  Apollo.MutationResult<UpdateUserStateMutation>;
export type UpdateUserStateMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserStateMutation,
  UpdateUserStateMutationVariables
>;
export const UpdateWardPatientAttributeCommentDocument = gql`
  mutation UpdateWardPatientAttributeComment(
    $input: WardPatientCommentsInput!
  ) {
    updateWardPatientAttributeComment(input: $input) {
      id
    }
  }
`;
export type UpdateWardPatientAttributeCommentMutationFn =
  Apollo.MutationFunction<
    UpdateWardPatientAttributeCommentMutation,
    UpdateWardPatientAttributeCommentMutationVariables
  >;

/**
 * __useUpdateWardPatientAttributeCommentMutation__
 *
 * To run a mutation, you first call `useUpdateWardPatientAttributeCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWardPatientAttributeCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWardPatientAttributeCommentMutation, { data, loading, error }] = useUpdateWardPatientAttributeCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWardPatientAttributeCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWardPatientAttributeCommentMutation,
    UpdateWardPatientAttributeCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWardPatientAttributeCommentMutation,
    UpdateWardPatientAttributeCommentMutationVariables
  >(UpdateWardPatientAttributeCommentDocument, options);
}
export type UpdateWardPatientAttributeCommentMutationHookResult = ReturnType<
  typeof useUpdateWardPatientAttributeCommentMutation
>;
export type UpdateWardPatientAttributeCommentMutationResult =
  Apollo.MutationResult<UpdateWardPatientAttributeCommentMutation>;
export type UpdateWardPatientAttributeCommentMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateWardPatientAttributeCommentMutation,
    UpdateWardPatientAttributeCommentMutationVariables
  >;
export const UpdateWardPatientConsultationStatusDocument = gql`
  mutation UpdateWardPatientConsultationStatus(
    $input: WardPatientUpdateConsultationStatusInput!
  ) {
    updateWardPatientConsultationStatus(input: $input) {
      id
    }
  }
`;
export type UpdateWardPatientConsultationStatusMutationFn =
  Apollo.MutationFunction<
    UpdateWardPatientConsultationStatusMutation,
    UpdateWardPatientConsultationStatusMutationVariables
  >;

/**
 * __useUpdateWardPatientConsultationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateWardPatientConsultationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWardPatientConsultationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWardPatientConsultationStatusMutation, { data, loading, error }] = useUpdateWardPatientConsultationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWardPatientConsultationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWardPatientConsultationStatusMutation,
    UpdateWardPatientConsultationStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWardPatientConsultationStatusMutation,
    UpdateWardPatientConsultationStatusMutationVariables
  >(UpdateWardPatientConsultationStatusDocument, options);
}
export type UpdateWardPatientConsultationStatusMutationHookResult = ReturnType<
  typeof useUpdateWardPatientConsultationStatusMutation
>;
export type UpdateWardPatientConsultationStatusMutationResult =
  Apollo.MutationResult<UpdateWardPatientConsultationStatusMutation>;
export type UpdateWardPatientConsultationStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateWardPatientConsultationStatusMutation,
    UpdateWardPatientConsultationStatusMutationVariables
  >;
export const UpdateWardPatientMonitoringStatusDocument = gql`
  mutation UpdateWardPatientMonitoringStatus(
    $input: WardPatientUpdateMonitoringStatusInput!
  ) {
    updateWardPatientMonitoringStatus(input: $input) {
      id
    }
  }
`;
export type UpdateWardPatientMonitoringStatusMutationFn =
  Apollo.MutationFunction<
    UpdateWardPatientMonitoringStatusMutation,
    UpdateWardPatientMonitoringStatusMutationVariables
  >;

/**
 * __useUpdateWardPatientMonitoringStatusMutation__
 *
 * To run a mutation, you first call `useUpdateWardPatientMonitoringStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWardPatientMonitoringStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWardPatientMonitoringStatusMutation, { data, loading, error }] = useUpdateWardPatientMonitoringStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWardPatientMonitoringStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWardPatientMonitoringStatusMutation,
    UpdateWardPatientMonitoringStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWardPatientMonitoringStatusMutation,
    UpdateWardPatientMonitoringStatusMutationVariables
  >(UpdateWardPatientMonitoringStatusDocument, options);
}
export type UpdateWardPatientMonitoringStatusMutationHookResult = ReturnType<
  typeof useUpdateWardPatientMonitoringStatusMutation
>;
export type UpdateWardPatientMonitoringStatusMutationResult =
  Apollo.MutationResult<UpdateWardPatientMonitoringStatusMutation>;
export type UpdateWardPatientMonitoringStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateWardPatientMonitoringStatusMutation,
    UpdateWardPatientMonitoringStatusMutationVariables
  >;
export const UpdateWardPatientTaskDocument = gql`
  mutation UpdateWardPatientTask($input: UpdateWardPatientTaskInput) {
    updateWardPatientTask(input: $input) {
      id
      type
      attributes {
        careSettingId
        personId
        wardPatientId
        wardPatientTaskType
        wardPatientTaskTypeDetail
        wardPatientTaskStatus
        dueDateTime
        completedDateTime
        taskContext
        isDeleted
        audit {
          version
        }
      }
    }
  }
`;
export type UpdateWardPatientTaskMutationFn = Apollo.MutationFunction<
  UpdateWardPatientTaskMutation,
  UpdateWardPatientTaskMutationVariables
>;

/**
 * __useUpdateWardPatientTaskMutation__
 *
 * To run a mutation, you first call `useUpdateWardPatientTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWardPatientTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWardPatientTaskMutation, { data, loading, error }] = useUpdateWardPatientTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWardPatientTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWardPatientTaskMutation,
    UpdateWardPatientTaskMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWardPatientTaskMutation,
    UpdateWardPatientTaskMutationVariables
  >(UpdateWardPatientTaskDocument, options);
}
export type UpdateWardPatientTaskMutationHookResult = ReturnType<
  typeof useUpdateWardPatientTaskMutation
>;
export type UpdateWardPatientTaskMutationResult =
  Apollo.MutationResult<UpdateWardPatientTaskMutation>;
export type UpdateWardPatientTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateWardPatientTaskMutation,
  UpdateWardPatientTaskMutationVariables
>;
export const UpdateWardPatientTestStatusDocument = gql`
  mutation UpdateWardPatientTestStatus(
    $input: WardPatientUpdateTestStatusInput!
  ) {
    updateWardPatientTestStatus(input: $input) {
      id
    }
  }
`;
export type UpdateWardPatientTestStatusMutationFn = Apollo.MutationFunction<
  UpdateWardPatientTestStatusMutation,
  UpdateWardPatientTestStatusMutationVariables
>;

/**
 * __useUpdateWardPatientTestStatusMutation__
 *
 * To run a mutation, you first call `useUpdateWardPatientTestStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWardPatientTestStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWardPatientTestStatusMutation, { data, loading, error }] = useUpdateWardPatientTestStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWardPatientTestStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWardPatientTestStatusMutation,
    UpdateWardPatientTestStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWardPatientTestStatusMutation,
    UpdateWardPatientTestStatusMutationVariables
  >(UpdateWardPatientTestStatusDocument, options);
}
export type UpdateWardPatientTestStatusMutationHookResult = ReturnType<
  typeof useUpdateWardPatientTestStatusMutation
>;
export type UpdateWardPatientTestStatusMutationResult =
  Apollo.MutationResult<UpdateWardPatientTestStatusMutation>;
export type UpdateWardPatientTestStatusMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateWardPatientTestStatusMutation,
    UpdateWardPatientTestStatusMutationVariables
  >;
export const GetCareSettingModulesDocument = gql`
  query GetCareSettingModules($careSettingId: ID!) {
    careSettingModules(careSettingId: $careSettingId) {
      attributes {
        moduleId
      }
    }
  }
`;

/**
 * __useGetCareSettingModulesQuery__
 *
 * To run a query within a React component, call `useGetCareSettingModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareSettingModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareSettingModulesQuery({
 *   variables: {
 *      careSettingId: // value for 'careSettingId'
 *   },
 * });
 */
export function useGetCareSettingModulesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCareSettingModulesQuery,
    GetCareSettingModulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCareSettingModulesQuery,
    GetCareSettingModulesQueryVariables
  >(GetCareSettingModulesDocument, options);
}
export function useGetCareSettingModulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCareSettingModulesQuery,
    GetCareSettingModulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCareSettingModulesQuery,
    GetCareSettingModulesQueryVariables
  >(GetCareSettingModulesDocument, options);
}
export type GetCareSettingModulesQueryHookResult = ReturnType<
  typeof useGetCareSettingModulesQuery
>;
export type GetCareSettingModulesLazyQueryHookResult = ReturnType<
  typeof useGetCareSettingModulesLazyQuery
>;
export type GetCareSettingModulesQueryResult = Apollo.QueryResult<
  GetCareSettingModulesQuery,
  GetCareSettingModulesQueryVariables
>;
export const GetHypertensionGuidelineApplicabilitySearchResultDocument = gql`
  query GetHypertensionGuidelineApplicabilitySearchResult(
    $input: HypertensionGuidelineApplicabilitySearchInput!
  ) {
    hypertensionGuidelineApplicabilitySearchResult(input: $input) {
      attributes {
        guidelineApplicabilityResult {
          applicableGuidelines {
            guideline
            guidelineState
          }
        }
      }
    }
  }
`;

/**
 * __useGetHypertensionGuidelineApplicabilitySearchResultQuery__
 *
 * To run a query within a React component, call `useGetHypertensionGuidelineApplicabilitySearchResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHypertensionGuidelineApplicabilitySearchResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHypertensionGuidelineApplicabilitySearchResultQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetHypertensionGuidelineApplicabilitySearchResultQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHypertensionGuidelineApplicabilitySearchResultQuery,
    GetHypertensionGuidelineApplicabilitySearchResultQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetHypertensionGuidelineApplicabilitySearchResultQuery,
    GetHypertensionGuidelineApplicabilitySearchResultQueryVariables
  >(GetHypertensionGuidelineApplicabilitySearchResultDocument, options);
}
export function useGetHypertensionGuidelineApplicabilitySearchResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHypertensionGuidelineApplicabilitySearchResultQuery,
    GetHypertensionGuidelineApplicabilitySearchResultQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHypertensionGuidelineApplicabilitySearchResultQuery,
    GetHypertensionGuidelineApplicabilitySearchResultQueryVariables
  >(GetHypertensionGuidelineApplicabilitySearchResultDocument, options);
}
export type GetHypertensionGuidelineApplicabilitySearchResultQueryHookResult =
  ReturnType<typeof useGetHypertensionGuidelineApplicabilitySearchResultQuery>;
export type GetHypertensionGuidelineApplicabilitySearchResultLazyQueryHookResult =
  ReturnType<
    typeof useGetHypertensionGuidelineApplicabilitySearchResultLazyQuery
  >;
export type GetHypertensionGuidelineApplicabilitySearchResultQueryResult =
  Apollo.QueryResult<
    GetHypertensionGuidelineApplicabilitySearchResultQuery,
    GetHypertensionGuidelineApplicabilitySearchResultQueryVariables
  >;
export const GetLatestHealthInformationDocument = gql`
  query GetLatestHealthInformation($wardPatientId: ID!, $personId: ID!) {
    clinicianPatientLatestInformation(
      wardPatientId: $wardPatientId
      personId: $personId
    ) {
      attributes {
        res_bmiValue
        latestHealthInformation {
          heightInformation {
            unit
            value
            created
          }
          diabetesInformation {
            diabetesStatus
            created
          }
          cvdInformation {
            hasCvd
            created
            familyHadCvd
          }
          ckdInformation {
            created
            ckdStatus
          }
        }
        latestObservationTypes {
          weightInformation {
            created
            unit
            value
          }
          fastingBloodGlucoseInformation {
            unit
            value
            created
          }
          hdlCholesterolInformation {
            created
            unit
            value
          }
          totalCholesterolInformation {
            created
            unit
            value
          }
        }
        personId
        wardPatientId
      }
      id
    }
  }
`;

/**
 * __useGetLatestHealthInformationQuery__
 *
 * To run a query within a React component, call `useGetLatestHealthInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestHealthInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestHealthInformationQuery({
 *   variables: {
 *      wardPatientId: // value for 'wardPatientId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetLatestHealthInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestHealthInformationQuery,
    GetLatestHealthInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestHealthInformationQuery,
    GetLatestHealthInformationQueryVariables
  >(GetLatestHealthInformationDocument, options);
}
export function useGetLatestHealthInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestHealthInformationQuery,
    GetLatestHealthInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestHealthInformationQuery,
    GetLatestHealthInformationQueryVariables
  >(GetLatestHealthInformationDocument, options);
}
export type GetLatestHealthInformationQueryHookResult = ReturnType<
  typeof useGetLatestHealthInformationQuery
>;
export type GetLatestHealthInformationLazyQueryHookResult = ReturnType<
  typeof useGetLatestHealthInformationLazyQuery
>;
export type GetLatestHealthInformationQueryResult = Apollo.QueryResult<
  GetLatestHealthInformationQuery,
  GetLatestHealthInformationQueryVariables
>;
export const GetLatestLifestyleInformationDocument = gql`
  query GetLatestLifestyleInformation($wardPatientId: ID!, $personId: ID!) {
    clinicianPatientLatestInformation(
      wardPatientId: $wardPatientId
      personId: $personId
    ) {
      attributes {
        latestHealthInformation {
          smokingInformation {
            created
            status
          }
        }
        personId
        wardPatientId
      }
      id
    }
  }
`;

/**
 * __useGetLatestLifestyleInformationQuery__
 *
 * To run a query within a React component, call `useGetLatestLifestyleInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestLifestyleInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestLifestyleInformationQuery({
 *   variables: {
 *      wardPatientId: // value for 'wardPatientId'
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetLatestLifestyleInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestLifestyleInformationQuery,
    GetLatestLifestyleInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestLifestyleInformationQuery,
    GetLatestLifestyleInformationQueryVariables
  >(GetLatestLifestyleInformationDocument, options);
}
export function useGetLatestLifestyleInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestLifestyleInformationQuery,
    GetLatestLifestyleInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestLifestyleInformationQuery,
    GetLatestLifestyleInformationQueryVariables
  >(GetLatestLifestyleInformationDocument, options);
}
export type GetLatestLifestyleInformationQueryHookResult = ReturnType<
  typeof useGetLatestLifestyleInformationQuery
>;
export type GetLatestLifestyleInformationLazyQueryHookResult = ReturnType<
  typeof useGetLatestLifestyleInformationLazyQuery
>;
export type GetLatestLifestyleInformationQueryResult = Apollo.QueryResult<
  GetLatestLifestyleInformationQuery,
  GetLatestLifestyleInformationQueryVariables
>;
export const GetObservationVisualsDocument = gql`
  query GetObservationVisuals($input: ObservationVisualsSearchInput!) {
    observationVisualsSearch(input: $input) {
      searchResults {
        recordedDateTime
        id
        observationType
        res_observationValues {
          values {
            name
            value
          }
        }
      }
    }
  }
`;

/**
 * __useGetObservationVisualsQuery__
 *
 * To run a query within a React component, call `useGetObservationVisualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObservationVisualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObservationVisualsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetObservationVisualsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetObservationVisualsQuery,
    GetObservationVisualsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetObservationVisualsQuery,
    GetObservationVisualsQueryVariables
  >(GetObservationVisualsDocument, options);
}
export function useGetObservationVisualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetObservationVisualsQuery,
    GetObservationVisualsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetObservationVisualsQuery,
    GetObservationVisualsQueryVariables
  >(GetObservationVisualsDocument, options);
}
export type GetObservationVisualsQueryHookResult = ReturnType<
  typeof useGetObservationVisualsQuery
>;
export type GetObservationVisualsLazyQueryHookResult = ReturnType<
  typeof useGetObservationVisualsLazyQuery
>;
export type GetObservationVisualsQueryResult = Apollo.QueryResult<
  GetObservationVisualsQuery,
  GetObservationVisualsQueryVariables
>;
export const GetObservationAbsoluteAverageDocument = gql`
  query GetObservationAbsoluteAverage($input: ObservationsAverageInput!) {
    observationsAbsoluteAverage(input: $input) {
      attributes {
        totalNoOfDays
        totalNoOfReadings
        observationsAbsoluteAverages {
          name
          absoluteAverages {
            res_averageValueInteger
          }
        }
      }
    }
  }
`;

/**
 * __useGetObservationAbsoluteAverageQuery__
 *
 * To run a query within a React component, call `useGetObservationAbsoluteAverageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObservationAbsoluteAverageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObservationAbsoluteAverageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetObservationAbsoluteAverageQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetObservationAbsoluteAverageQuery,
    GetObservationAbsoluteAverageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetObservationAbsoluteAverageQuery,
    GetObservationAbsoluteAverageQueryVariables
  >(GetObservationAbsoluteAverageDocument, options);
}
export function useGetObservationAbsoluteAverageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetObservationAbsoluteAverageQuery,
    GetObservationAbsoluteAverageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetObservationAbsoluteAverageQuery,
    GetObservationAbsoluteAverageQueryVariables
  >(GetObservationAbsoluteAverageDocument, options);
}
export type GetObservationAbsoluteAverageQueryHookResult = ReturnType<
  typeof useGetObservationAbsoluteAverageQuery
>;
export type GetObservationAbsoluteAverageLazyQueryHookResult = ReturnType<
  typeof useGetObservationAbsoluteAverageLazyQuery
>;
export type GetObservationAbsoluteAverageQueryResult = Apollo.QueryResult<
  GetObservationAbsoluteAverageQuery,
  GetObservationAbsoluteAverageQueryVariables
>;
export const GetObservationsForTodaysReadingsDocument = gql`
  query GetObservationsForTodaysReadings(
    $input: ObservationsForTodaysReadingsInput!
  ) {
    observationsForTodaysReadings(input: $input) {
      id
      observationTypeReadings {
        id
        observationReadings {
          recordedDateTime
          res_observationValues {
            values {
              name
              value
            }
          }
          id
        }
        observationType
        res_dailyAverage
      }
    }
  }
`;

/**
 * __useGetObservationsForTodaysReadingsQuery__
 *
 * To run a query within a React component, call `useGetObservationsForTodaysReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObservationsForTodaysReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObservationsForTodaysReadingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetObservationsForTodaysReadingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetObservationsForTodaysReadingsQuery,
    GetObservationsForTodaysReadingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetObservationsForTodaysReadingsQuery,
    GetObservationsForTodaysReadingsQueryVariables
  >(GetObservationsForTodaysReadingsDocument, options);
}
export function useGetObservationsForTodaysReadingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetObservationsForTodaysReadingsQuery,
    GetObservationsForTodaysReadingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetObservationsForTodaysReadingsQuery,
    GetObservationsForTodaysReadingsQueryVariables
  >(GetObservationsForTodaysReadingsDocument, options);
}
export type GetObservationsForTodaysReadingsQueryHookResult = ReturnType<
  typeof useGetObservationsForTodaysReadingsQuery
>;
export type GetObservationsForTodaysReadingsLazyQueryHookResult = ReturnType<
  typeof useGetObservationsForTodaysReadingsLazyQuery
>;
export type GetObservationsForTodaysReadingsQueryResult = Apollo.QueryResult<
  GetObservationsForTodaysReadingsQuery,
  GetObservationsForTodaysReadingsQueryVariables
>;
export const GetSearchPersonDocument = gql`
  query GetSearchPerson($input: SearchPersonInput) {
    searchPerson(input: $input) {
      personalInformation {
        title
        surname
        firstName
        dateOfBirth
        nhsNumber
        genderIdentifiedAs
        genderAssignedAtBirth
        pronouns
        mobility
        ethnicity
        primaryAddress {
          line1
          postalCode
        }
      }
      contactInformation {
        primaryEmailAddress
        primaryContactNumber
      }
      userId
      isStandalonePatient
      personId
      careSettingId
    }
  }
`;

/**
 * __useGetSearchPersonQuery__
 *
 * To run a query within a React component, call `useGetSearchPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchPersonQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSearchPersonQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSearchPersonQuery,
    GetSearchPersonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSearchPersonQuery, GetSearchPersonQueryVariables>(
    GetSearchPersonDocument,
    options
  );
}
export function useGetSearchPersonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchPersonQuery,
    GetSearchPersonQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchPersonQuery,
    GetSearchPersonQueryVariables
  >(GetSearchPersonDocument, options);
}
export type GetSearchPersonQueryHookResult = ReturnType<
  typeof useGetSearchPersonQuery
>;
export type GetSearchPersonLazyQueryHookResult = ReturnType<
  typeof useGetSearchPersonLazyQuery
>;
export type GetSearchPersonQueryResult = Apollo.QueryResult<
  GetSearchPersonQuery,
  GetSearchPersonQueryVariables
>;
export const GetRegisteredCareSettingCliniciansDocument = gql`
  query GetRegisteredCareSettingClinicians($careSettingId: ID!) {
    registeredCareSettingClinicians(careSettingId: $careSettingId) {
      attributes {
        email
        firstName
        surname
        res_mobileNumber
        careSettingClinicianUser {
          attributes {
            userState
          }
        }
        userId
        careSettingClinicianModuleAvailability {
          attributes {
            moduleId
            moduleShortName
            availabilityStatus
          }
        }
        careSettingClinicianModuleRoles {
          attributes {
            moduleRoleId
          }
        }
      }
      id
    }
  }
`;

/**
 * __useGetRegisteredCareSettingCliniciansQuery__
 *
 * To run a query within a React component, call `useGetRegisteredCareSettingCliniciansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisteredCareSettingCliniciansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisteredCareSettingCliniciansQuery({
 *   variables: {
 *      careSettingId: // value for 'careSettingId'
 *   },
 * });
 */
export function useGetRegisteredCareSettingCliniciansQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRegisteredCareSettingCliniciansQuery,
    GetRegisteredCareSettingCliniciansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetRegisteredCareSettingCliniciansQuery,
    GetRegisteredCareSettingCliniciansQueryVariables
  >(GetRegisteredCareSettingCliniciansDocument, options);
}
export function useGetRegisteredCareSettingCliniciansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegisteredCareSettingCliniciansQuery,
    GetRegisteredCareSettingCliniciansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRegisteredCareSettingCliniciansQuery,
    GetRegisteredCareSettingCliniciansQueryVariables
  >(GetRegisteredCareSettingCliniciansDocument, options);
}
export type GetRegisteredCareSettingCliniciansQueryHookResult = ReturnType<
  typeof useGetRegisteredCareSettingCliniciansQuery
>;
export type GetRegisteredCareSettingCliniciansLazyQueryHookResult = ReturnType<
  typeof useGetRegisteredCareSettingCliniciansLazyQuery
>;
export type GetRegisteredCareSettingCliniciansQueryResult = Apollo.QueryResult<
  GetRegisteredCareSettingCliniciansQuery,
  GetRegisteredCareSettingCliniciansQueryVariables
>;
export const TasksByStatusesDocument = gql`
  query TasksByStatuses(
    $wardPatientId: ID!
    $statuses: [TaskListStatus!]
    $limit: Int
  ) {
    tasksByStatuses(
      wardPatientId: $wardPatientId
      statuses: $statuses
      limit: $limit
    ) {
      id
      type
      attributes {
        careSettingId
        personId
        wardPatientId
        wardPatientTaskType
        wardPatientTaskTypeDetail
        wardPatientTaskStatus
        dueDateTime
        completedDateTime
        taskContext
        isDeleted
        audit {
          version
          lastUpdated
          updatedByUser {
            careSettingClinician {
              attributes {
                displayName
                firstName
                surname
              }
            }
          }
          created
        }
      }
    }
  }
`;

/**
 * __useTasksByStatusesQuery__
 *
 * To run a query within a React component, call `useTasksByStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksByStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksByStatusesQuery({
 *   variables: {
 *      wardPatientId: // value for 'wardPatientId'
 *      statuses: // value for 'statuses'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTasksByStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<
    TasksByStatusesQuery,
    TasksByStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TasksByStatusesQuery, TasksByStatusesQueryVariables>(
    TasksByStatusesDocument,
    options
  );
}
export function useTasksByStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TasksByStatusesQuery,
    TasksByStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TasksByStatusesQuery,
    TasksByStatusesQueryVariables
  >(TasksByStatusesDocument, options);
}
export type TasksByStatusesQueryHookResult = ReturnType<
  typeof useTasksByStatusesQuery
>;
export type TasksByStatusesLazyQueryHookResult = ReturnType<
  typeof useTasksByStatusesLazyQuery
>;
export type TasksByStatusesQueryResult = Apollo.QueryResult<
  TasksByStatusesQuery,
  TasksByStatusesQueryVariables
>;
export const GetUserProfileBySafIdDocument = gql`
  query GetUserProfileBySafId($safId: String!) {
    userProfile(safId: $safId) {
      id
      attributes {
        audit {
          created
          createdBy
          lastUpdated
          updatedBy
          version
        }
        defaultCareSettingId
        defaultWardId
        userState
        safId
      }
      careSetting {
        attributes {
          name
          shortName
          relatedOrganisations {
            externalReferenceId
            name
            type
          }
        }
      }
      userModuleAvailability {
        type
        id
        attributes {
          availabilityStatus
          careSettingModuleId
          moduleId
          moduleName
          moduleShortName
          userId
        }
      }
      careSettingClinician {
        attributes {
          firstName
          displayName
        }
      }
      userModuleRoles {
        attributes {
          moduleRoleId
        }
      }
    }
  }
`;

/**
 * __useGetUserProfileBySafIdQuery__
 *
 * To run a query within a React component, call `useGetUserProfileBySafIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileBySafIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileBySafIdQuery({
 *   variables: {
 *      safId: // value for 'safId'
 *   },
 * });
 */
export function useGetUserProfileBySafIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserProfileBySafIdQuery,
    GetUserProfileBySafIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserProfileBySafIdQuery,
    GetUserProfileBySafIdQueryVariables
  >(GetUserProfileBySafIdDocument, options);
}
export function useGetUserProfileBySafIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserProfileBySafIdQuery,
    GetUserProfileBySafIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserProfileBySafIdQuery,
    GetUserProfileBySafIdQueryVariables
  >(GetUserProfileBySafIdDocument, options);
}
export type GetUserProfileBySafIdQueryHookResult = ReturnType<
  typeof useGetUserProfileBySafIdQuery
>;
export type GetUserProfileBySafIdLazyQueryHookResult = ReturnType<
  typeof useGetUserProfileBySafIdLazyQuery
>;
export type GetUserProfileBySafIdQueryResult = Apollo.QueryResult<
  GetUserProfileBySafIdQuery,
  GetUserProfileBySafIdQueryVariables
>;
export const GetWardPatientDocument = gql`
  query GetWardPatient($wardPatientId: ID!, $careSettingId: ID) {
    wardPatient(wardPatientId: $wardPatientId, careSettingId: $careSettingId) {
      id
      attributes {
        admissionDateTime
        comments
        covid19Status
        observationTypes
        submittedObservationTypes
        careSettingId
        lastObservationDateTime
        lastReviewedDateTime
        res_lastReviewedBy
        res_admittedBy
        monitoredConditions {
          condition
          monitoringStatus {
            status
            statusUpdateDateTime
            statusUpdatedBy
            res_statusUpdatedBy
          }
          consultationStatus {
            status
            statusUpdateDateTime
            statusUpdatedBy
            res_statusUpdatedBy
          }
          testStatus {
            status
            statusUpdateDateTime
            statusUpdatedBy
            res_statusUpdatedBy
          }
        }
        audit {
          version
        }
      }
      wardDischarge {
        id
        attributes {
          comments
          dischargeReason
          dischargeActualDateTime
        }
      }
      careSettingPatient {
        id
        attributes {
          admissionStatus
          admissionStatusUpdateDateTime
        }
        person {
          id
          attributes {
            registeredGPSurgery
            contactInformation {
              primaryEmailAddress
              primaryContactNumber
            }
            contactParty {
              primaryContactNumber
              personRelationshipType
              humanNames {
                given
                family
              }
            }
            personalInformation {
              title
              surname
              pronouns
              primaryAddress {
                postalCode
                line1
              }
              nhsNumber
              mobility
              genderIdentifiedAs
              genderAssignedAtBirth
              firstName
              ethnicity
              dateOfBirth
            }
          }
        }
      }
      ward {
        id
        attributes {
          wardGroupId
        }
      }
    }
  }
`;

/**
 * __useGetWardPatientQuery__
 *
 * To run a query within a React component, call `useGetWardPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardPatientQuery({
 *   variables: {
 *      wardPatientId: // value for 'wardPatientId'
 *      careSettingId: // value for 'careSettingId'
 *   },
 * });
 */
export function useGetWardPatientQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWardPatientQuery,
    GetWardPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWardPatientQuery, GetWardPatientQueryVariables>(
    GetWardPatientDocument,
    options
  );
}
export function useGetWardPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardPatientQuery,
    GetWardPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWardPatientQuery, GetWardPatientQueryVariables>(
    GetWardPatientDocument,
    options
  );
}
export type GetWardPatientQueryHookResult = ReturnType<
  typeof useGetWardPatientQuery
>;
export type GetWardPatientLazyQueryHookResult = ReturnType<
  typeof useGetWardPatientLazyQuery
>;
export type GetWardPatientQueryResult = Apollo.QueryResult<
  GetWardPatientQuery,
  GetWardPatientQueryVariables
>;
export const GetWardPatientCommentsDocument = gql`
  query GetWardPatientComments($wardPatientId: ID!) {
    wardPatientComments(wardPatientId: $wardPatientId) {
      attributes {
        audit {
          created
          createdBy
          createdByUser {
            careSettingClinician {
              attributes {
                displayName
              }
            }
          }
        }
        comment
        commentType
        recordedDateTime
        wardPatientId
      }
      id
      type
    }
  }
`;

/**
 * __useGetWardPatientCommentsQuery__
 *
 * To run a query within a React component, call `useGetWardPatientCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardPatientCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardPatientCommentsQuery({
 *   variables: {
 *      wardPatientId: // value for 'wardPatientId'
 *   },
 * });
 */
export function useGetWardPatientCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWardPatientCommentsQuery,
    GetWardPatientCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWardPatientCommentsQuery,
    GetWardPatientCommentsQueryVariables
  >(GetWardPatientCommentsDocument, options);
}
export function useGetWardPatientCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardPatientCommentsQuery,
    GetWardPatientCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWardPatientCommentsQuery,
    GetWardPatientCommentsQueryVariables
  >(GetWardPatientCommentsDocument, options);
}
export type GetWardPatientCommentsQueryHookResult = ReturnType<
  typeof useGetWardPatientCommentsQuery
>;
export type GetWardPatientCommentsLazyQueryHookResult = ReturnType<
  typeof useGetWardPatientCommentsLazyQuery
>;
export type GetWardPatientCommentsQueryResult = Apollo.QueryResult<
  GetWardPatientCommentsQuery,
  GetWardPatientCommentsQueryVariables
>;
export const GetWardPatientConditionAssessmentsDocument = gql`
  query GetWardPatientConditionAssessments(
    $wardPatientId: ID!
    $careSettingId: ID!
  ) {
    wardPatientConditionAssessment(
      wardPatientId: $wardPatientId
      careSettingId: $careSettingId
    ) {
      id
      attributes {
        assessmentComments
        assessmentStartDateZoned
        assessmentType
        hypertensionAssessment {
          averageDiastolic
          averageSystolic
          conditionTestTypeOther
          conditionTestTypes
          consultationRequiredYN
          consultationTimeframe
          customConsultationTimeframe
          fitToSelfMonitorYN
          qriskScore
          reasonForNoUrgentClinicalIntervention
          totalDays
          testsRequiredYN
          totalReadings
          urgentClinicalInterventionRequiredYN
          urgentClinicalInterventionTakenPlaceYN
        }
        monitoredCondition
        medicationAssessment {
          hasEverTakenBloodPressureMedicationYN
          isTakingBloodPressureMedicationYN
          medicationProtocol
          newMedicationProtocol
          comments
          medicationOutcome
          stoppedMedicationOutcomeComments
          guidelineRecommendationDecisions {
            guidelineRecommended
            guidelineAccepted
            recommendationDeclinedReason
            otherRecommendationDeclinedReasonComments
          }
        }
        res_createdBy
        audit {
          created
        }
      }
    }
  }
`;

/**
 * __useGetWardPatientConditionAssessmentsQuery__
 *
 * To run a query within a React component, call `useGetWardPatientConditionAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardPatientConditionAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardPatientConditionAssessmentsQuery({
 *   variables: {
 *      wardPatientId: // value for 'wardPatientId'
 *      careSettingId: // value for 'careSettingId'
 *   },
 * });
 */
export function useGetWardPatientConditionAssessmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWardPatientConditionAssessmentsQuery,
    GetWardPatientConditionAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWardPatientConditionAssessmentsQuery,
    GetWardPatientConditionAssessmentsQueryVariables
  >(GetWardPatientConditionAssessmentsDocument, options);
}
export function useGetWardPatientConditionAssessmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardPatientConditionAssessmentsQuery,
    GetWardPatientConditionAssessmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWardPatientConditionAssessmentsQuery,
    GetWardPatientConditionAssessmentsQueryVariables
  >(GetWardPatientConditionAssessmentsDocument, options);
}
export type GetWardPatientConditionAssessmentsQueryHookResult = ReturnType<
  typeof useGetWardPatientConditionAssessmentsQuery
>;
export type GetWardPatientConditionAssessmentsLazyQueryHookResult = ReturnType<
  typeof useGetWardPatientConditionAssessmentsLazyQuery
>;
export type GetWardPatientConditionAssessmentsQueryResult = Apollo.QueryResult<
  GetWardPatientConditionAssessmentsQuery,
  GetWardPatientConditionAssessmentsQueryVariables
>;
export const GetWardPatientStatusesDocument = gql`
  query GetWardPatientStatuses($wardPatientId: ID!) {
    wardPatient(wardPatientId: $wardPatientId) {
      id
      attributes {
        monitoredConditions {
          condition
          monitoringStatus {
            status
          }
          consultationStatus {
            status
          }
          testStatus {
            status
          }
        }
      }
    }
  }
`;

/**
 * __useGetWardPatientStatusesQuery__
 *
 * To run a query within a React component, call `useGetWardPatientStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardPatientStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardPatientStatusesQuery({
 *   variables: {
 *      wardPatientId: // value for 'wardPatientId'
 *   },
 * });
 */
export function useGetWardPatientStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWardPatientStatusesQuery,
    GetWardPatientStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWardPatientStatusesQuery,
    GetWardPatientStatusesQueryVariables
  >(GetWardPatientStatusesDocument, options);
}
export function useGetWardPatientStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardPatientStatusesQuery,
    GetWardPatientStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWardPatientStatusesQuery,
    GetWardPatientStatusesQueryVariables
  >(GetWardPatientStatusesDocument, options);
}
export type GetWardPatientStatusesQueryHookResult = ReturnType<
  typeof useGetWardPatientStatusesQuery
>;
export type GetWardPatientStatusesLazyQueryHookResult = ReturnType<
  typeof useGetWardPatientStatusesLazyQuery
>;
export type GetWardPatientStatusesQueryResult = Apollo.QueryResult<
  GetWardPatientStatusesQuery,
  GetWardPatientStatusesQueryVariables
>;
export const GetWardPatientsDocument = gql`
  query GetWardPatients(
    $wardId: ID!
    $sortKey: WardPatientsSortKey
    $ascending: Boolean
    $from: Int
    $limit: Int
    $search: String
    $monitoredConditions: [PatientDiagnosis!]
    $registeredGPSurgeries: [String!]
    $admissionStatuses: [AdmissionStatus!]
    $monitoringStatuses: [MonitoringStatusType!]
    $testStatuses: [MonitoredConditionTestStatusType!]
    $consultationStatuses: [MonitoredConditionConsultationStatusType!]
    $taskDueTypes: [TaskListType!]
  ) {
    wardPatientsSearch(
      wardId: $wardId
      sortKey: $sortKey
      ascending: $ascending
      from: $from
      limit: $limit
      search: $search
      monitoredConditions: $monitoredConditions
      registeredGPSurgeries: $registeredGPSurgeries
      admissionStatuses: $admissionStatuses
      monitoringStatuses: $monitoringStatuses
      testStatuses: $testStatuses
      consultationStatuses: $consultationStatuses
      taskDueTypes: $taskDueTypes
    ) {
      hits
      searchResults {
        id
        careSettingPatientId
        careSettingId
        personId
        firstName
        surname
        nhsNumber
        dateOfBirth
        lastReviewedDateTime
        lastObservationDateTime
        dnMonitoredConditions
        monitoredConditions
        registeredGPSurgery
        admissionStatus
        monitoringStatusByCondition
        consultationStatusByCondition
        testStatusByCondition
        nextTaskDueId
        nextTaskDueDateTime
        nextTaskDueType
        nextTaskDueTypeDetail
      }
    }
  }
`;

/**
 * __useGetWardPatientsQuery__
 *
 * To run a query within a React component, call `useGetWardPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWardPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWardPatientsQuery({
 *   variables: {
 *      wardId: // value for 'wardId'
 *      sortKey: // value for 'sortKey'
 *      ascending: // value for 'ascending'
 *      from: // value for 'from'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      monitoredConditions: // value for 'monitoredConditions'
 *      registeredGPSurgeries: // value for 'registeredGPSurgeries'
 *      admissionStatuses: // value for 'admissionStatuses'
 *      monitoringStatuses: // value for 'monitoringStatuses'
 *      testStatuses: // value for 'testStatuses'
 *      consultationStatuses: // value for 'consultationStatuses'
 *      taskDueTypes: // value for 'taskDueTypes'
 *   },
 * });
 */
export function useGetWardPatientsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWardPatientsQuery,
    GetWardPatientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWardPatientsQuery, GetWardPatientsQueryVariables>(
    GetWardPatientsDocument,
    options
  );
}
export function useGetWardPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWardPatientsQuery,
    GetWardPatientsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWardPatientsQuery,
    GetWardPatientsQueryVariables
  >(GetWardPatientsDocument, options);
}
export type GetWardPatientsQueryHookResult = ReturnType<
  typeof useGetWardPatientsQuery
>;
export type GetWardPatientsLazyQueryHookResult = ReturnType<
  typeof useGetWardPatientsLazyQuery
>;
export type GetWardPatientsQueryResult = Apollo.QueryResult<
  GetWardPatientsQuery,
  GetWardPatientsQueryVariables
>;
