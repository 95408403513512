import { parse } from 'date-fns';
import { useRedirectAndReturnState } from './useRedirectAndReturnState';
import { useTracking } from './useTracking';
import {
  useAddWardPatientTaskMutation,
  useUpdateWardPatientTaskMutation,
  TasksByStatusesDocument,
  TaskListStatus,
  TaskListType,
} from 'services/graphql';
import { useApp } from 'context/app/AppContext';
import { useTasks } from 'context/tasks/TasksContext';
import type { TaskFormData } from 'components/TaskList/types';

export const useAddOrUpdateTaskList = (onCompleted?: () => void) => {
  const wardPatientId = useRedirectAndReturnState('wardPatientId');
  const { careSetting } = useApp();
  const { taskDetails, personId, showDeleteTaskModal } = useTasks();
  const { trackEvent } = useTracking();

  const [
    addWardPatientTaskMutation,
    { data: addData, loading: addLoading, error: addError },
  ] = useAddWardPatientTaskMutation();
  const [
    updateWardPatientTaskMutation,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateWardPatientTaskMutation();

  const refetchQueries = [
    {
      query: TasksByStatusesDocument,
      variables: {
        wardPatientId,
        statuses: [
          TaskListStatus.Draft,
          TaskListStatus.Pending,
          TaskListStatus.Complete,
        ],
        limit: null,
      },
    },
  ];

  const onSubmit = (
    formData: Partial<TaskFormData & { status: TaskListStatus }>
  ) => {
    const input = {
      careSettingId: careSetting.id!,
      personId,
      wardPatientId,
      dueDateTime: formData?.taskDueDate
        ? new Date(formData?.taskDueDate).toISOString()
        : parse(
            taskDetails?.dueDateTime!,
            'dd/MM/yy',
            new Date()
          ).toISOString(),
    };

    if (taskDetails) {
      updateWardPatientTaskMutation({
        variables: {
          input: {
            ...input,
            wardPatientTaskStatus: formData?.status ?? taskDetails.status,
            wardPatientTaskType: taskDetails.type,
            wardPatientTaskTypeDetail:
              taskDetails.type === TaskListType.Other ? taskDetails.text : '',
            version: taskDetails.version,
            completedDateTime:
              formData?.status === TaskListStatus.Complete
                ? new Date().toISOString()
                : null,
            isDeleted: showDeleteTaskModal,
            wardPatientTaskId: taskDetails.id,
          },
        },
        refetchQueries,
        onCompleted,
      });

      if (formData?.status === TaskListStatus.Complete) {
        trackEvent(`Task List - Marked task as Completed`, {
          taskType: taskDetails.type,
        });
      } else if (showDeleteTaskModal) {
        trackEvent(`Task List - Deleted Task`, {
          taskType: taskDetails.type,
        });
      } else {
        trackEvent(`Task List - Edited Task`, {
          taskType: taskDetails.type,
        });
      }

      return;
    }

    addWardPatientTaskMutation({
      variables: {
        input: {
          ...input,
          wardPatientTaskStatus: formData.status || TaskListStatus.Pending,
          wardPatientTaskType: formData.task?.value as TaskListType,
          wardPatientTaskTypeDetail: formData?.other!,
        },
      },
      refetchQueries,
      onCompleted,
    });

    trackEvent('Task List - Submitted new task', {
      taskType: formData.task?.value!,
    });
  };

  return {
    data: addData || updateData,
    loading: addLoading || updateLoading,
    error: addError || updateError,
    onSubmit,
  };
};
